import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NbDialogService, NbToastrService } from '@nebular/theme';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { ChallengeService } from '../../services/challenge.service';
import * as _ from 'lodash';
import { states } from "../../../assets/js/states";
import { forkJoin, Subject } from 'rxjs';
import { HttpClient, } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { MatDialog } from '@angular/material/dialog';
import { CommondialogComponent } from '../../@core/miscellaneous/commondialog/commondialog.component';
import { ExcelService } from '../../services/excel.service';
import { StateService } from "../../services/state.service";
import { InvestorService } from '../../investor.service';

declare let $: any;

export let SELECT_ITEMS = [
  { value: 'G1', label: 'G1' },
  { value: 'G2', label: 'G2' },
  { value: 'G3', label: 'G3' },
];

@Component({
  selector: 'ngx-investor',
  templateUrl: './investor.component.html',
  styleUrls: ['./investor.component.scss']
})
export class InvestorComponent implements OnInit {

  projectmanager!:boolean;
  financemanager!:boolean;
  superadmin!:boolean;
  data: any;
  // visible:boolean = false
  filteredlist=[];
  filter:boolean = false;
  filterby = {};
  options: any[] = [];
  types = SELECT_ITEMS;
  dropdownSettings:IDropdownSettings = {};
  dropdownSettings_t:IDropdownSettings = {};
  dropdownSettings_s: IDropdownSettings={};
  dropdownSettings_schemes: IDropdownSettings={};

  selectedItems = [];
  dropdownList = [];
  sectors: any = [];
  dropdownList1 = [];
  tempdata = [];
  selectedItems1 = [];
  public domain = [];
  public sector = [];
  public schemes = [];

  originalData: any[];

  myArray: any[] = [];
  itemName: string;
  itemCategory: string;
  selectcity:string="";
  selectstate:any=[];
  selectdomain:any=[];
  selectsector:any=[];
  selectstatus:any=[];
  selectscheme:any=[];
  selectedStates:any[];
  dropdownstates: any = [];

  selectedStatus:any[];
  dropdownstatus:any[]=['PENDING','REJECTED','APPROVED'];
  selectedScheme:any[];
  dropdownscheme:any[]=[];
  modalUpdateForm:FormGroup;
  name: any;
  singledata = {name:''};
  action:any;
  currentData: any;
  inValidMessage: boolean;
  modaldata: any;
  id: any;
  schemeForm: FormGroup;
  alldomains: any;
  groupbydomains: any;
  submitted: boolean;
  filterMode: boolean = true;
  resetMode: boolean = false;
  filterValue: any;
  show:boolean=true;
  mat_data: string;
  // currentPage: number = 1;
  // tablearray: number;
  // paginationarr=[];
  sid: any;
  p: number = 1;
  // itemsPerPage: 50;
  totalItems: number;
  currentPage:number=1; 
  allStates: any;
  approvedCount: number = 0;
  rejectedCount: number = 0;
  pendingCount: number = 0;
  pageSize: number= 25;
  loading: boolean = true; 
  activeFilter: string | null = null; 
  filterValue1:any;
  viewback: any;


  constructor(
    private investorService: InvestorService,
    private router: Router,
    private fb: FormBuilder,
    private toastrService: NbToastrService,
    private challengeService: ChallengeService,
    private _route:ActivatedRoute,
    private http: HttpClient,
    private dialogService: NbDialogService,
    public dialog: MatDialog,
    private excelService:ExcelService,
    private statesservice : StateService
  ) {
    this.filterValue = this._route.snapshot.queryParamMap.get('filter');
    console.log("filtervalue", this.filterValue);
    // if (localStorage.getItem('status') ) {
    //   // If it exists, assign its value to this.filterValue
    //   this.filterValue = localStorage.getItem('status');
    // }  
    this.activeFilter = this.filterValue;
    if(localStorage.getItem('viewback'))
    {
      this.viewback = localStorage.getItem('viewback');
    }
   }
  
  ngOnInit(): void {
  }
  ngOnDestroy(): void{
    // remove style for ui break on scroll
    var htmlElement = document.documentElement;
    if (htmlElement) {
      htmlElement.style.top = ''; 
      htmlElement.style.position = '';
    }
   
      // remove style for ui break on scroll
      localStorage.removeItem('viewback');
 }

 //  pagination page redirection code
onPageChange(page: number): void {
  // Update the current page and navigate to the new URL
  this.p = page;
  this.router.navigate([], {
    relativeTo: this._route,
    queryParams: { page: page },
    queryParamsHandling: 'merge',
  });
 
  this.loadData(page);
}
private loadData(page: number): void {
  // Implement your logic to load data based on the page
  console.log('Loading data for page', page);
}
//  pagination page redirection code

  listAllIncubators(){
    this.activeFilter = null;
    let data={
      search_entity: "INVESTOR",
      "page": 0, "page_size": 5000
    }
    this.loading = true;
    this.investorService.filterIncubator(data)
      .subscribe((res:any)=>{
        console.log("fullll",res)
        this.data=res.results;
        this.originalData = this.data;
        this.totalItems = +res.total_search_results_count; 
        this.updateStatusCounts(this.originalData);    
        this.loading = false;
        this.onPageChange(1) 
      },(err)=>{
        console.log('err',err);
      })
  }
  async getAll(){
    
    console.log("aaaaaoni", this.totalItems);

    return new Promise((resolve,reject)=>{
      let data={
        search_entity: "INVESTOR",
        "page": 0, "page_size": this.totalItems
      }
      this.investorService.filterIncubator(data)
        .subscribe((res:any)=>{
          this.data=res.results;
          this.originalData = this.data;
        },
        (err)=>{
          console.log('err',err);
        })
        resolve(this.totalItems)
    })
}
 

 // ngAfterViewInit(): void {
    // document.getElementsByClassName('pagination-previous')[0].setAttribute('id','previous');
    // document.getElementById('previous').onclick = (res:any) => {
    //   //this.listAllIncubators();
    //   //place pagination api here for on page click
    //   // let p= res.target.outerText;
    //   // console.log("Page::", p);
    //   console.log('currentpage',this.currentPage-1);
    //   let data={
    //     "search_entity": "INCUBATOR",
    //     "page": this.currentPage-1, 
    //     "page_size": 50
    //   }
    //   console.log('payload_data',data);
    //   this.investorService.filterIncubator(data)
    //     .subscribe((res:any)=>{
    //       data=res.results;

    //       // this.currentPage= this.currentPage;
    //       console.log("216666", data);
  
  
    //     })
    // }
 // }

 backfilter(){  
  if(this.viewback == 'true')
  {
    const getArray = localStorage.getItem('filterArray');
    const parsedArray = JSON.parse(getArray);
    this.myArray = parsedArray;
    const stateValue = parsedArray.find(item => item.name === 'state')?.value;
    const statusValue = parsedArray.find(item => item.name === 'status')?.value;
    const cityvalue = parsedArray.find(item => item.name === 'city')?.value;
    const domainvalue = parsedArray.find(item => item.name === 'domain')?.value;
    const scheme = parsedArray.find(item => item.name === 'schemes')?.value;
    this.selectedStates = stateValue;
    this.selectedStatus = statusValue;
    this.selectcity = cityvalue
    this.selectedItems = domainvalue;
    this.selectedScheme = scheme;
  }
  

    if(localStorage.getItem('status'))
    {
      this.activeFilter = localStorage.getItem('status');
    }

   


  let data = {
    filters: this.myArray,
    search_entity: "INVESTOR",
    "page": 0, "page_size": 5000

  };

 
  console.log("data____0603", data);
 
 
  this.investorService.filterIncubator(data).subscribe((res: any) => {
      // console.log("res myarray___0603:", res);
      this.data = res.results;        
      // this.updateStatusCounts(this.data);
      this.loading = false;
    });
   
  
}

  filterItem(filterValue) {
    
    if(filterValue == 'null')
    {
     this.activeFilter = null;
    } 
     
    
    this.filterMode = false;
    this.resetMode = true;
    this.myArray = [];
    var domains = [];
    localStorage.removeItem('status');

    if(this.selectdomain && this.selectdomain.length > 0)
    {
      for (var i = 0; i < this.selectdomain.length; i++) {
        domains.push(this.selectdomain[i].item_text);
      }
      if (this.selectdomain.length != 0) {
        this.myArray.push({
          name: "domain",
          value: domains,
        });
      }
    }
    var sector = [];
    for(var i = 0 ; i < this.selectsector.length ; i++){
      sector.push(this.selectsector[i].item_text);
    }

    var schemes = [];
    if(this.selectscheme!=undefined){
      for(var i = 0 ; i < this.selectscheme.length ; i++){
        schemes.push(this.selectscheme[i].item_text);
      }
      console.log('scheme', schemes);
    }
 

    // var myItem =[{ name: "city", value: this.selectcity}]
    // var myItem1= [{ name: "state", value: [...this.selectstate] }]
    // var myItem2= [{ name: "domain", value: domains }]
    // var myItem3= [{ name: "sector", value: sector }]
    // var myItem4= [{ name: "status", value: [...this.selectstatus] }]
    // this.myArray.push(...myItem, ...myItem1, ...myItem2, ...myItem3, ...myItem4)
    // console.log("array:",this.myArray);

    if (this.selectcity !== '') {
      this.myArray.push({
        name:'city',
        value:this.selectcity
      });
      // console.log("selectcity:",this.myArray);
    }
    if (this.selectstate && this.selectstate.length > 0 ){
      this.myArray.push({
        name: 'state',
        value: this.selectstate
      })
    }
    console.log('domain_select',this.selectdomain);
    // if(this.selectdomain.length !=0){
    //   this.myArray.push({
    //     name: 'domain',
    //     value: domains
    //   })
    //   // console.log('domains',this.myArray);
    // }
    // if(this.selectsector.length !=0){
    //   this.myArray.push({
    //     name: 'sector',
    //     value: sector
    //   })
    // }


    if(schemes.length !=0){
      this.myArray.push({
        name: 'schemes',
        value: schemes
      })
    }
      console.log("selectscheme:",this.myArray);

    if(this.selectstatus.length !=0){
      this.myArray.push({
        name: 'status',
        value: this.selectstatus
      })
      this.activeFilter = this.selectstatus;
      this.filterValue1 = this.selectstatus
    }

    // if (this.selectstatus.length == 0) {
    //   this.myArray.push({
    //     name: "status",
    //     value: filterValue,
    //   });
      
      
    //   console.log("filter value by stats", this.myArray);
    // }
    const jsonString = JSON.stringify(this.myArray);
    localStorage.setItem('filterArray', jsonString); 
   
      let data = {
      "filters": this.myArray,
      "search_entity": "INVESTOR",
      "page": 0, "page_size": 5000

    }
      console.log('data____final',data);
      
    if(this.filterValue == null){
      this.filterMode=true;
      this.investorService.filterIncubator(data)
      .subscribe((res:any)=>{
        console.log("res myarray___0603:", res);
        this.data = res.results;
        this.onPageChange(1) 
        this.updateStatusCounts(this.data);
        const statusCount = {
          approvedData: this.approvedCount,
          rejectedData: this.rejectedCount,
          pendingData: this.pendingCount
        };
        const countstring = JSON.stringify(statusCount);
        localStorage.setItem('countdata', countstring);
      })
     
    }
    else {
      // this.filterMode=false;

      let data = {
        "filters": [
            {
                "name": "status",
                "value": [
                    this.filterValue
                ]
            }
        ],
        "search_entity": "INVESTOR",
        "page": 0, "page_size": 5000

    }
      console.log("data____filterdata", data);
      this.investorService.filterIncubator(data).subscribe((res: any) => {
        console.log("pendinggggg.........:", res);
        this.data = res.results;
        this.updateStatusCounts(this.data);
        this.loading = false

      });
      this.filterMode=false;

    }
  }

  countFilter(data)
  {

    this.myArray = [];
    var domains = [];

    
    
    if(this.selectdomain && this.selectdomain.length > 0)
    {
      for (var i = 0; i < this.selectdomain.length; i++) {
        domains.push(this.selectdomain[i].item_text);
      }
      if (this.selectdomain.length != 0) {
        this.myArray.push({
          name: "domain",
          value: domains,
        });
      }
    }
    var sector = [];
    var schemes = [];
    if(this.selectscheme!=undefined){
      for(var i = 0 ; i < this.selectscheme.length ; i++){
        schemes.push(this.selectscheme[i].item_text);
      }
      console.log('scheme', schemes);
    }

    if (this.selectcity !== "") {
      this.myArray.push({
        name: "city",
        value: this.selectcity,
      });
      console.log("selectcity:", this.myArray);
    }
    if (this.selectstate && this.selectstate.length > 0) {
      this.myArray.push({
        name: "state",
        value: this.selectstate,
      });
    }
    // if (this.selectdomain.length != 0) {
    //   this.myArray.push({
    //     name: "domain",
    //     value: domains,
    //   });
    // }
    // if (this.selectsector.length != 0) {
    //   this.myArray.push({
    //     name: "sector",
    //     value: sector,
    //   });
    // }
    this.myArray.push({
      name: "status",
      value: data,
    });
    let fitlere_data = data;
    this.activeFilter = data;
    this.filterValue1 = data;   
    // this.onPageChange(1) 
    localStorage.removeItem('status');

    if(this.viewback == 'true')
    {
      const getArray = localStorage.getItem('filterArray');
     const parsedArray = JSON.parse(getArray);
     this.myArray = parsedArray;
      this.myArray = this.myArray.filter(item => item.name !== 'status');
    }
     // this.myArray = this.myArray.filter(item => item.name !== 'status');
     if(data == 'All')
     {
       // this.myArray = this.myArray.filter(item => item.name !== 'status');
       this.myArray.push({
         name: "status",
         value: ["PENDING", "REJECTED", "APPROVED"]
       });
       this.activeFilter = null;
       this.selectedStatus = ["PENDING", "REJECTED", "APPROVED"];
       const jsonString = JSON.stringify(this.myArray);
       localStorage.setItem('filterArray', jsonString); 
      
     }
     else {
       
         // this.myArray = this.myArray.filter(item => item.name !== 'status');
      
      
       this.myArray.push({
         name: "status",
         value: [data],
       });
       this.selectedStatus = [data];
       const jsonString = JSON.stringify(this.myArray);
       localStorage.setItem('filterArray', jsonString); 
     }
   
    
     
     const jsonString = JSON.stringify(this.myArray);
     localStorage.setItem('filterArray', jsonString); 

    let data1 = {
      filters: this.myArray,
      "search_entity": "INVESTOR",
      "page": 0, "page_size": 5000

  }
    this.investorService.filterIncubator(data1).subscribe((res: any) => {     
     
      this.data = res.results;
      console.log('count status data is ', this.data)
      // this.updateStatusCounts(this.data);
      this.onPageChange(1) 
      const statusCount = {
        approvedData: this.approvedCount,
        rejectedData: this.rejectedCount,
        pendingData: this.pendingCount
      };
      const countstring = JSON.stringify(statusCount);
      localStorage.setItem('countdata', countstring);
    });
    // this.p= 1;
  }
  countAllFilter()
  {
    this.activeFilter = null;
    localStorage.removeItem('status');
    this.onPageChange(1) 
    this.resetFilters()
    this.listAllIncubators();
     this.updateStatusCounts(this.data);
  }

  resetFilters() {
    this.resetMode = false; 
    this.filterMode = true;
    // clear the filter criteria object
    this.data = {};
    this.selectcity='';
    this.selectstate='';
    this.selectdomain='';
    // this.selectsector='';
    this.selectscheme='';
    this.selectstatus='';
    // reset the filtered data to the original data
    // this.data = this.originalData;
    this.listAllIncubators();
    this.updateStatusCounts(this.data);
    localStorage.removeItem('status');
    this.activeFilter = null;
    this.onPageChange(1) 
    localStorage.removeItem('filterArray');    
    localStorage.removeItem('viewback')
    this.viewback = 'false';
  }

 
 
  // paginate(page:any){
  //   console.log('event_1234',page);
  //   console.log("350", page.target.outerText);
  //   console.log('page1234',page);
  //   this.currentPage = +page.target.outerText;
  //   console.log('currentpage',this.currentPage);
  //   if(page != 1){
  //    console.log('pagination_previous',document.querySelector('.pagination-previous'));
  //    document.querySelector('.pagination-previous').classList.remove('disabled');
  //    console.log('pagination_previous_disabled',document.querySelector('.pagination-previous'));
  //   }
  //   else {
  //     document.querySelector('.pagination-previous').className +=' disabled'
  //   }
  //   let data={
  //     search_entity: "INCUBATOR",
  //     "page": this.currentPage, "page_size": 50
  //   }
  //   console.log('data_pagination',data);
  //   this.investorService.filterIncubator(data)
  //     .subscribe((res:any)=>{
  //       this.data=res.results;
  //       page= page;
  //       // console.log("359", page);
  //     })
  // }
  
  // onPageChanged(event: any) {
  //   this.currentPage = event.page;
  //   this.pageSize = event.itemsPerPage;
  //   // Fetch data from API based on currentPage and pageSize
  //   let data={
  //         search_entity: "INCUBATOR",
  //         "page": this.currentPage, "page_size": this.pageSize
  //       }
  //       console.log('data_pagination',data);
  //       this.investorService.filterIncubator(data)
  //         .subscribe((res:any)=>{
  //           this.data=res.results;
              
    
  //         })
     
  // }
  


  // handleNext(currentPage){
  //   let data={
  //     search_entity: "INCUBATOR",
  //     "page": currentPage + 1 , "page_size": 10
  //   }
  //   this.investorService.filterIncubator(data)
  //     .subscribe((res:any)=>{
  //       this.data=res.results;
  //     })
  // }
  // handlePrev(currentPage){
  //   let data={
  //     search_entity: "INCUBATOR",
  //     "page": currentPage - 1 , "page_size": 10
  //   }
  //   this.investorService.filterIncubator(data)
  //     .subscribe((res:any)=>{
  //       this.data=res.results;
  //     })
  //     // const url = `${this.data}?page=${data.page}`;
  //     // return this.http.get<any[]>(url);
  // }
  getAllSchemes(){
    this.investorService.getschemeList()
      .subscribe((res:any)=>{
        this.options= res.results;
        console.log("SchemeLIST:", this.options);

        let data = [];
      for(var i = 0 ; i < this.options.length ; i++){
        data.push({
          item_id:i+1 , item_text:this.options[i].name
        })
      }
      this.dropdownscheme = [...data];
      console.log("schmeeeeeeeeee",this.dropdownscheme);
      })
  }
   changestatus(id:string,value:string){
    //  this.mat_data = value.toLowerCase(); 

    var htmlElement = document.documentElement;
    if (htmlElement) {
      htmlElement.style.top = '0'; 
      htmlElement.style.position = 'sticky';
    }
    this.mat_data = value;
    // console.log('datamatdata::', this.mat_data);
    if(this.mat_data=='REJECTED'){
      this.mat_data= 'Reject'
    }else if(this.mat_data=="APPROVED"){
      this.mat_data= 'Approve'
    }
    const dialogRef=this.dialog.open(CommondialogComponent,{
          data: { status: this.mat_data, name: 'Investor' }
        })
        dialogRef.afterClosed().subscribe(result => {
          if(result==true){
            var data = {
              "status": value
            }
            this.investorService.actionIncubator(data,id).subscribe((res:any)=>{
                console.log('change_status',res);
                // this.getAllIncubatorsData();
                // this.listAllIncubators();
                localStorage.removeItem('myData');
                this.toastrService.success("Successfully updated Status!");
                const updatedIncubatorIndex = this.data.findIndex(incubator => incubator.id === id);
                if (updatedIncubatorIndex !== -1) {
                  this.data[updatedIncubatorIndex].status = value;
                }
              },
              (error) => {
              this.inValidMessage = true;
              this.toastrService.warning("Unable to Update Status!");
            })
          }
        })
  }
 
  editModal(modaldata){
    this.modaldata=modaldata;
    console.log("modeeee::", this.modaldata);
    this.modalUpdateForm.controls["modalIncubatorName"].setValue(modaldata.name);
    this.modalUpdateForm.controls["group"].setValue(modaldata.group);
    // this.modalUpdateForm.controls["schemes"].setValue(modaldata.scheme_ids);

    // this.singledata['name'] = modaldata.name
    // console.log("modalForm::", this.modalUpdateForm.value);
  }

  // updateModal(){
  //   let data={
  //     group: this.modalUpdateForm.controls.centerType.value,
  //     // scheme: this.modalUpdateForm.controls.schemes.value
  //   }
  //   this.investorService.editIncubatorModal(data, this.modaldata.id)
  //     .subscribe((res:any)=>{
  //       console.log("updateTSDAta::", res);
  //       this.modalUpdateForm.reset();
  //       this.toastrService.success("Data Updated Successfully!");
  //     },
  //       (error) => {
  //       this.inValidMessage = true;
  //       this.toastrService.warning("Unable to Update records!");
  //     })
  // }
  schemeval(event:any){
    console.log('event____scheme',event.target.value)
     this.sid= event.target.value;
  }
  updateData(){
    console.log("sID:", this.sid);
    console.log("md form::",this.modalUpdateForm);
       let data=  {
          "schemes": [
              {
                  "id": this.sid,
                  "group": this.modalUpdateForm.controls.group.value
              }
          ]
      }
    
    this.investorService.updateScheme(data, this.modaldata.id)
      .subscribe((res:any)=>{
        // this.schemeForm.reset();
        this.listAllIncubators();
        console.log("schemUpdated:", res);
        this.toastrService.success("Schemes Updated Successfully!");
      },
        (error) => {
        this.inValidMessage = true;
        this.toastrService.warning("Unable to Update Schemes!");
      })
  }

  // onCheckboxChange(checked: boolean) {
  //   const checkboxValue = checked ? 'Scheme' : 'TIDE 2.0';
  //   console.log(checkboxValue);
  // }
  

  // updateSchemes() {
  
  //   let data={
  //     group: this.modalUpdateForm.controls.group.value,
  //     scheme_ids:[+this.modalUpdateForm.controls.schemes.value]
  //   }
  //   let id=this.modaldata.id;
  //   const putRequest1 =this.investorService.editIncubatorModal(data, this.modaldata.id)
  //   const putRequest2=this.investorService.updateScheme(data, this.modaldata.id)
    
  //   // Use forkJoin to combine the two requests
  //   forkJoin([putRequest1, putRequest2]).subscribe(results => {
  //     // Handle the results of both requests here
  //     console.log('PUT request 1 result:', results[0]);
  //     console.log('PUT request 2 result:', results[1]);
  //     this.listAllIncubators();
  //     this.toastrService.success("Modal Updated Successfully!");
  //   },
  //   (error) => {
  //   this.inValidMessage = true;
  //   this.toastrService.warning("Unable to Update Modal!");
  // })
      
  // }
  
  getDomains(){
    this.challengeService.getalldomains().subscribe((res:any)=>{
      this.alldomains = res.results;
      let data = [];
      for(var i = 0 ; i < this.alldomains.length ; i++){
        data.push({
          item_id:i+1 , item_text:this.alldomains[i].name
        })
      }
      this.dropdownList = [...data];
      // console.log('data_domain',data);
      this.groupbydomains = _.groupBy(this.alldomains,'name');
      console.log('group domains',this.groupbydomains);
    })
  }

 
  async onItemSelectDomain(event){
    console.log('onItemSelect',this.groupbydomains,event);
    console.log('getsector',this.groupbydomains[`${event.item_text}`][0]['sectors'])
    await this.groupbysectors(event);
    console.log('sectors',this.sectors);
    for(var i = 0 ; i<this.sectors.length ; i++) {
      this.tempdata.push({
        item_id:i+1 , item_text:this.sectors[i].name
      })
    }
    this.dropdownList1 = [...this.tempdata];
    console.log('sectors_dropdown',this.dropdownList1);
  }

  async onItemDeSelectDomain(event){
    console.log('event',event);
  }
  async groupbysectors(event){
    return new Promise((resolve,reject)=>{
      this.challengeService.setSectorData(this.groupbydomains[`${event.item_text}`][0]['sectors'])
      this.sectors.push(...this.groupbydomains[`${event.item_text}`][0]['sectors']);
      resolve(this.sectors);
    })
  }  

  gotosection(el:any){
    // this.visible = !this.visible;
    el.scrollIntoView({behaviour:'smooth'});
  }
  preview(d){
    console.log("pCheck::", d);
    this.router.navigateByUrl(`/admin/investortemp/${d}`);
    if(this.filterValue1.length > 0)
    {
      localStorage.setItem('status',this.filterValue1)
      console.log("filte value is ==> ", this.filterValue1)
    }
   
  }
  updateScheme(d){
    console.log("updatescc", d);
    this.router.navigateByUrl(`/admin/indincubator/${d}`);

  }

  exportData(dataArray: any[]) {
    const data = dataArray.map((d) => ({
      Name: d.name,
      Domain: Array.isArray(d.domain) ? d.domain.join(', ') : d.domain,
      AffiliateName: d.affiliate_name,
      Sector: d.sector,
      City: d.city,
      // Description: d.short_description,
      State: d.state,
      Country: d.country,
      ZipCode:d?.contact_info?.addresses?.zipCode,
      WebsiteUrl:d?.contact_info?.weburl,
      CeoDetails: d?.team_info ? `CEO Firstname=${d.team_info?.ceoFirstName}, Lastname=${d.team_info?.ceoLastName}, Phone=${d.team_info?.ceoPhoneNo}, Email=${d.team_info?.ceoEmail}` : '',
      // CeoDetails:  d?.team_info?.ceoFirstName ? `CEO Firstname=${d.team_info?.ceoFirstName}` : '',
      // [
      //   d?.team_info?.ceoFirstName ? `CEO Firstname=${d.team_info?.ceoFirstName}` : '',
      //   d?.team_info?.ceoLastName ? `CEO Lastname=${d.team_info?.ceoLastName}` : '',
      //   d?.team_info?.ceoPhoneNo ? `Mobile=${d.team_info?.ceoPhoneNo}` : '',
      //   d?.team_info?.ceoEmail ? `Email=${d.team_info?.ceoEmail}` : '',
        
      // ],
      TeamDetails:Array.isArray(d?.team_info?.team_array) ? d?.team_info?.team_array.map(info => `First Name=${info.teamFirstName},Last Name= ${info.teamLastName},Mobile= ${info.teamPhoneNo},Email=${info.teamEmail}`).join(', ') : '',
      SocialInfo: d?.social_info ? `Facebook=${d.social_info.facebook}, Instagram=${d.social_info.instagram},Twitter=${d.social_info.twitter},LinkedIn=${d.social_info.linkedIn},Youtube=${d.social_info.youtube}` : '',

      // SocialInfo: [
      //   d?.social_info?.facebook ? `Facebook=${d.social_info.facebook}` : '',
      //   d?.social_info?.instagram ? `Instagram=${d.social_info.instagram}` : '',
      //   d?.social_info?.twitter ? `Twitter=${d.social_info.twitter}` : '',
      //   d?.social_info?.linkedIn ? `LinkedIn=${d.social_info.linkedIn}` : '',
      //   d?.social_info?.youtube ? `Youtube=${d.social_info.youtube}` : '',
      //   d?.social_info?.koo ? `Koo=${d.social_info.koo}` : ''
      // ],
      Scheme:Array.isArray(d.scheme_names) ? d.scheme_names.join(', ') : d.scheme_names,
      Status: d.status,
      'Profile Email': d?.team_info?.ceoEmail,
      'Login Email':d?.login_email,
    }));
    this.exportAsXLSX(data)
    // this.generateExcelFile(data, "myData.xlsx");
    console.log("Export:", data);
  }

  exportAsXLSX(data):void {
    this.excelService.exportAsExcelFile(data, 'Investor Report');
  }
  downloadIncubatorList(){
    this.exportData(this.data);
  }
  // state
  getStates() {
    this.statesservice.getallstates().subscribe((res: any) => {
      this.allStates = res.results;
      console.log("all states =>", this.allStates);
      this.dropdownstates = this.allStates
     
   
    });
  }
  // state


  getCountsByStatus(startups) {
    const statusCounts = {};
    startups.forEach((startup) => {
      const status = startup.status;
      if (status) {
        if (statusCounts.hasOwnProperty(status)) {
          statusCounts[status]++;
        } else {
          statusCounts[status] = 1;
        }
      }
    });   

    return statusCounts;
  }
  updateStatusCounts(data) {
    const statusCounts = this.getCountsByStatus(data);
    console.log("Status Counts:", statusCounts);
    // Separate the counts
    this.approvedCount = statusCounts["APPROVED"] || 0;
    this.rejectedCount = statusCounts["REJECTED"] || 0;
    this.pendingCount = statusCounts["PENDING"] || 0;
    
  }



}
