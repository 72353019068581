import { Component, Input, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import * as ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { NbDialogService, NbToastrService } from "@nebular/theme";
import { GuestHouseService } from "../../../services/guest-house.service";
import { Router,ActivatedRoute } from "@angular/router";
import { ChallengeService } from "../../../services/challenge.service";
import { ShowcaseDialogComponent } from "../../../@core/miscellaneous/dialog/showcase-dialog/showcase-dialog.component";
import { states } from "../../../../assets/js/states";
import * as _ from "lodash";
import { ExcelService } from "../../../services/excel.service";
import { IDropdownSettings } from "ng-multiselect-dropdown";
import { isNullishCoalesce } from "typescript";
import * as XLSX from 'xlsx';

@Component({
  selector: "ngx-guest-house",
  templateUrl: "./guest-house.component.html",
  styleUrls: ["./guest-house.component.scss"],
})
export class GuestHouseComponent implements OnInit {
  @Input() newid: any;
  @Input() entity_type: any;
  public Editor = ClassicEditor;
  public editorConfig = {
    toolbar: {
      items: [
        "heading",
        "|",
        "alignment", // <--- ADDED
        "bold",
        "italic",
        "link",
        "bulletedList",
        "blockQuote",
        "undo",
        "redo",
      ],
    },
    link: {
      decorators: {
        toggleDownloadable: {
          mode: "manual",
          label: "Downloadable",
          attributes: {
            download: "file",
          },
        },
        openInNewTab: {
          mode: "manual",
          label: "Open in a new tab",
          defaultValue: true, // This option will be selected by default.
          attributes: {
            target: "_blank",
            rel: "noopener noreferrer",
          },
        },
      },
    },
  };
  url = "src/assets/images/view_profive_nav_icon.png";
  msg = "";
  guestHouseForm: FormGroup;
  formSubmitted: boolean;
  // id = "section";
  numericPattern = "^[0-9]*$";
  data: any;
  inValidMessage: boolean;
  dataTable: any;
  list: any = [];
  byId: any;
  dataByID: any;
  urls = [];
  public arrfoodType: any[] = [{ name: "INCLUDED" }, { name: "EXCLUDED" }];
  public arrshareType: any[] = [{ name: "SINGLE" }, { name: "DOUBLE" }];
  public arrstates: any[] = states;
  fileInputLabel: string;
  imageUrl: string | ArrayBuffer | null = null;
  filesizeerr = false;
  files: any = [];
  createMode: boolean = true;
  editMode: boolean;
  guesthouse_id: any;
  currentData: any;
  images = [];
  modaldata = {
    name: "",
    availableRoomsNo: "",
    foodType: "",
    shareType: "",
    description: "",
    img1: "",
    img2: "",
    img3: "",
    img4: "",
    incharge_details: {
      name: "",
      phone: "",
      designation: "",
      email: "",
    },
    address_line1: "",
    city: "",
    state: "",
    zip_code: "",
    delegatesName: "",
    delegatesCharge: "",
    startupName: "",
    startupCharge: "",
    icDelegates: "",
    icDelegatesCharges: "",
    otherCharges: "",
    otherName: "",
    
  };
  uploadMode: boolean = false;
  indexes = [];
  myArray: any[] = [];
  paginationEnabled: boolean = true;
  p: number = 1;
  pageSize: number = 25;
  selectedUserType: any;
  selectedUser: any[];
  selectedStatus: any = [];
  totalItems: number;
  dropdownSettings_s: IDropdownSettings = {};
  dropdownSettings: IDropdownSettings = {};
  dropdownSettingst: IDropdownSettings = {};
  dropdownSettingsS: IDropdownSettings = {};
  filterValue: any;
  originalData: any[];
  dropdownuserType: any[] = ["INCUBATOR", "COE", "ACCELERATOR"];
  dropdownuser: any[] = [];
  dropdownstatus: any[] = ["APPROVED", "PENDING", "DECLINED"]
  filterMode: boolean = true;
  approvedCount: number = 0;
  rejectedCount: number = 0;
  pendingCount: number = 0;
  venueFilterForm       : FormGroup;
  previewimageurl: any = [0,0,0,0];
  filterEnable: boolean = false;
  activeFilter: string | null = null; 
  filterValue1 = null;
  viewback: any;

  constructor(
    private fb: FormBuilder,
    private guestHouseService: GuestHouseService,
    private toastrService: NbToastrService,
    private router: Router,
    private challengeService: ChallengeService,
    private dialogService: NbDialogService,
    private excelService:ExcelService,
    private _route:ActivatedRoute,
  ) {}

  ngOnInit(): void {
    this.guestHouseForm = this.fb.group({
      name: ["", [Validators.required,Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/)]],
      availableRoomsNo: [
        "",
        [Validators.required, Validators.pattern("^[0-9]*$")],
      ],
      // foodType: ["", Validators.required],
      // shareType: ["", Validators.required],
      addressLine1: ["", [Validators.required,Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/)]],
      cityName: ["", [Validators.required,Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/)]],
      stateName: ["", Validators.required],
      zipCode: ["", [Validators.required,Validators.pattern(/^\d{6}$/)]],
      description: ["", Validators.required],
      inchargeName: ["", [Validators.required,Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/)]],
      contactNumber: [
        "",
        [Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")],
      ],
      designation: ["", [Validators.required,Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/)]],
      emailAddress: [
        "",
        [
          Validators.required,
          Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$"),
        ],
      ],

      delegatesName: [""],
      delegatesCharge: ["",Validators.required],
      startupName: [""],
      startupCharge: ["", Validators.required],
      icDelegates: [""],
      icDelegatesCharges: ["", Validators.required],
      otherCharges: ["",Validators.required],
      otherName: ["",[Validators.required,Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/)]],
      uploadedImage: [""],
      _img1: ["",Validators.required],
      _img2: [""],
      _img3: [""],
      _img4: [""],
    });

    this.venueFilterForm = this.fb.group({
      selectedUserType: ['ALL'],
      start_date: [''], 
      end_date: [''],
      
    });


    if(this.filterValue != null) {
      this.filterItem(this.filterValue);
    }
    else{       
      this.getAllData();
      
    }

   
    this.selectedUserType = {};
    this.selectedUser = [];
    this.selectedStatus = []    
    
    this.dropdownSettings = {
      singleSelection: false,
      idField: "item_id",
      textField: "item_text",
      selectAllText: "Select All",
      unSelectAllText: "UnSelect All",
      // itemsShowLimit: 3
      allowSearchFilter: true,
    };
    this.dropdownSettings_s = {
      singleSelection: false,
      idField: "item_id",
      textField: "item_text",
      selectAllText: "Select All",
      unSelectAllText: "UnSelect All",
      // itemsShowLimit: 3
      allowSearchFilter: true,
    };
    this.dropdownSettingst={
      singleSelection: false,
      idField: "item_id",
      textField: "item_text",
      selectAllText: "Select All",
      unSelectAllText: "UnSelect All",
      // itemsShowLimit: 3
      allowSearchFilter: true,
    }

    this.dropdownSettingsS = {
      singleSelection: true,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',     
      allowSearchFilter: true
    };
    
    
  }
  submitGuestData() {
    console.log('-=-=-=-=-=-=-=-=-')
    this.formSubmitted = true;
    this.uploadMode = false;
    if (this.guestHouseForm.valid) {
      console.log('++++++++++++++')
      console.log(this.guestHouseForm.value);
      const formData = new FormData();

      for (let i = 0; i < this.files.length; i++) {
        const file: File = this.files[i];
        formData.append("file", file);
      }
      console.log("formDATA", formData);
      this.challengeService
        .uploadManyFiles(formData, "guesthouse-docs")
        .subscribe((response: any) => {
          console.log("image response", response.results);
          this.guestHouseForm.get("uploadedImage").setValue(response.results);
          this.fileInputLabel = undefined;
          let data = {
            name: this.guestHouseForm.controls.name.value,
            no_of_rooms: this.guestHouseForm.controls.availableRoomsNo.value,
            description: this.guestHouseForm.controls.description.value,
            food_type: "INCLUDED",
            sharing_type: "SINGLE",
            address_line1: this.guestHouseForm.controls.addressLine1.value,
            city: this.guestHouseForm.controls.cityName.value,
            state: this.guestHouseForm.controls.stateName.value,
            zip_code: this.guestHouseForm.controls.zipCode.value.toString(),
            incharge_details: {
              name: this.guestHouseForm.controls.inchargeName.value,
              phone: this.guestHouseForm.controls.contactNumber.value,
              email: this.guestHouseForm.controls.emailAddress.value,
              designation: this.guestHouseForm.controls.designation.value,
            },
            sections: {
              list: [
                {
                  name: "accomodation_for",
                  target_user_list: [
                    {
                      name: "Meity Delegate",
                      charge:
                        this.guestHouseForm.controls.delegatesCharge.value,
                    },
                    {
                      name: "startup",
                      charge: this.guestHouseForm.controls.startupCharge.value,
                    },
                    {
                      name: "IC Delegates/Officials",
                      charge:
                        this.guestHouseForm.controls.icDelegatesCharges.value,
                    },
                    {
                      name: this.guestHouseForm.controls.otherName.value,
                      charge: this.guestHouseForm.controls.otherCharges.value,
                    },
                  ],
                },
              ],
            },
            location: "dummy",
            documents: this.guestHouseForm.controls.uploadedImage.value,
          };
          this.guestHouseService.createGuestData(data).subscribe(
            (res: any) => {
              this.dataTable = res.results;
              console.log("Guest dataTable::", this.dataTable);
              this.guestHouseForm.reset();
              this.toastrService.success("Data Added Successfully!");
              this.getAllData();
            },
            (error) => {
              this.inValidMessage = true;
              this.toastrService.warning("Unable to Add records!");
            }
          );
          this.files = [];
          this.images = [];
        });
    } else {
      console.log('-----------')
      this.guestHouseForm.markAllAsTouched();
    }
  }

  getAllData() {
    let data = {    
      search_entity: "GUESTHOUSE",
      "page": 0, "page_size": 2984
      };
    this.guestHouseService.getAllGuestHouseData(data).subscribe((res: any) => {
      this.data = res.results;
      this.list = res.results;
      console.log("list", this.list);
      this.totalItems = +res.total_search_results_count;
      console.log("334___________", this.totalItems);
      this.activeFilter = null;
      this.originalData = this.list;
      this.updateStatusCounts(this.originalData);
      if(this.newid && this.entity_type){
        this.applyInitialFilters() 
      }
    });
  }

  editData(data: any, el: any) {
    try {
      this.uploadMode = false;
      console.log("data__edit", data);
      el.scrollIntoView({ behaviour: "smooth" });
      console.log("edit data", data);
      this.editMode = true; //change btn to update
      this.createMode = false;
      this.guesthouse_id = data.id;
      this.guestHouseForm.controls["name"].setValue(data.name);
      this.guestHouseForm.controls["availableRoomsNo"].setValue(
        data.no_of_rooms
      );
      //this.guestHouseForm.controls["foodType"].setValue("INCLUDED");
     // this.guestHouseForm.controls["shareType"].setValue("SINGLE");
      this.guestHouseForm.controls["description"].setValue(data.description);
      this.guestHouseForm.controls["addressLine1"].setValue(data.address_line1);
      this.guestHouseForm.controls["cityName"].setValue(data.city);
      this.guestHouseForm.controls["stateName"].setValue(data.state);
      this.guestHouseForm.controls["zipCode"].setValue(data.zip_code);
      this.guestHouseForm.controls["inchargeName"].setValue(
        data.incharge_details["name"]
      );
      this.guestHouseForm.controls["contactNumber"].setValue(
        data.incharge_details["phone"]
      );
      this.guestHouseForm.controls["designation"].setValue(
        data.incharge_details["designation"]
      );
      this.guestHouseForm.controls["emailAddress"].setValue(
        data.incharge_details["email"]
      );
      this.guestHouseForm.controls["uploadedImage"].setValue(data.documents);
      this.guestHouseForm.controls["delegatesName"].setValue(
        data.sections.list[0].target_user_list[0].name
      );
      this.guestHouseForm.controls["delegatesCharge"].setValue(
        data.sections.list[0].target_user_list[0].charge
      );
      this.guestHouseForm.controls["startupName"].setValue(
        data.sections.list[0].target_user_list[1].name
      );
      this.guestHouseForm.controls["startupCharge"].setValue(
        data.sections.list[0].target_user_list[1].charge
      );
      this.guestHouseForm.controls["icDelegates"].setValue(
        data.sections.list[0].target_user_list[2].name
      );
      this.guestHouseForm.controls["icDelegatesCharges"].setValue(
        data.sections.list[0].target_user_list[2].charge
      );
      this.guestHouseForm.controls["otherName"].setValue(
        data.sections.list[0].target_user_list[3].name
      );
      this.guestHouseForm.controls["otherCharges"].setValue(
        data.sections.list[0].target_user_list[3].charge
      );
      this.guestHouseForm.get("_img1").setValue(data.documents[0]?.url ? data.documents[0]?.url : data.documents[0]);
      this.guestHouseForm.get("_img2").setValue(data.documents[1]?.url ? data.documents[1]?.url : data.documents[1]);
      this.guestHouseForm.get("_img3").setValue(data.documents[2]?.url ? data.documents[2]?.url : data.documents[2]);
      this.guestHouseForm.get("_img4").setValue(data.documents[3]?.url ? data.documents[3]?.url : data.documents[3]);

      console.log('553', this.files)
      if(data.documents[0]){
        const file = data.documents[0]?.url ? data.documents[0]?.url : data.documents[0]; 
        this.files.splice(0, 1, file);
      }
      if(data.documents[1]){
        const file = data.documents[1]?.url ? data.documents[1]?.url : data.documents[1]; 
        this.files.splice(1, 1, file);
      }
      if(data.documents[2]){
        const file = data.documents[2]?.url ? data.documents[2]?.url : data.documents[2]; 
        this.files.splice(2, 1, file);
      }
      if(data.documents[3]){
        const file = data.documents[3]?.url ? data.documents[3]?.url : data.documents[3]; 
        this.files.splice(3, 1, file);
      }
      console.log('570', this.files)

      this.previewimageurl=[];   
      // for (let i = 0; i < data.documents.length; i++) {
      //   const last_part = data.documents[i]?.url?.split("/");
      //   this.images[i] = last_part[last_part.length - 1];
      // }
      if (data.documents && data.documents.length > 0) {
        for (let i = 0; i < data.documents.length; i++) {
            const last_part = data.documents[i].url ? data.documents[i].url.split("/") : data.documents[i].split("/");
            if (data.documents[i]?.url ? data.documents[i]?.url : data.documents[i]) {
                this.images[i] = last_part[last_part.length - 1];
                this.previewimageurl.push(data.documents[i]?.url ? data.documents[i]?.url : data.documents[i]);
            }
            console.log("previewimageurl +++++++++" + i, this.previewimageurl[i]);
        }
    }
      console.log("images", this.images);
      console.log('files', this.files);
      console.log("image url", this.previewimageurl);
      console.log("uploded images => ",this.guestHouseForm.controls["uploadedImage"].value)
      console.log("images", this.images);      
    } catch (error) {
      console.log(error);
    }
  }

  updateGuesthouse() {
    if (this.editMode) {
      this.editMode = true;
      this.formSubmitted = true;

      console.log(this.previewimageurl)
      if(this.previewimageurl){
        var filesUpdated = this.previewimageurl.filter(item => item != '');
        console.log(filesUpdated);
      }
      this.previewimageurl = [];
      this.previewimageurl = filesUpdated

      if (this.guestHouseForm.valid) {
        console.log(this.guestHouseForm.value);
        let data = {
          name: this.guestHouseForm.controls.name.value,
          no_of_rooms: this.guestHouseForm.controls.availableRoomsNo.value,
          description: this.guestHouseForm.controls.description.value,
          food_type: "INCLUDED",
          sharing_type: "SINGLE",
          address_line1: this.guestHouseForm.controls.addressLine1.value,
          city: this.guestHouseForm.controls.cityName.value,
          state: this.guestHouseForm.controls.stateName.value,
          zip_code: this.guestHouseForm.controls.zipCode.value.toString(),
          incharge_details: {
            name: this.guestHouseForm.controls.inchargeName.value,
            phone: this.guestHouseForm.controls.contactNumber.value,
            email: this.guestHouseForm.controls.emailAddress.value,
            designation: this.guestHouseForm.controls.designation.value,
          },
          sections: {
            list: [
              {
                name: "accomodation_for",
                target_user_list: [
                  {
                    name: "Meity Delegate",
                    charge: this.guestHouseForm.controls.delegatesName.value,
                  },
                  {
                    name: "startup",
                    charge: this.guestHouseForm.controls.startupCharge.value,
                  },
                  {
                    name: "IC Delegates/Officials",
                    charge:
                      this.guestHouseForm.controls.icDelegatesCharges.value,
                  },
                  {
                    name: this.guestHouseForm.controls.otherName.value,
                    charge: this.guestHouseForm.controls.otherCharges.value,
                  },
                ],
              },
            ],
          },
          location: "dummy",
          // documents: this.guestHouseForm.controls.uploadedImage.value,
          documents: this.previewimageurl
        };
        const formData = new FormData();
        if (this.uploadMode == true) {
          console.log(" upload mode files", this.previewimageurl);
          let previousFiles = this.previewimageurl;
          for (let i = 0; i < this.files.length; i++) {
            if (this.files[i]) {
              const file: File = this.files[i];
              formData.append("file", file);
            }
          }
          console.log("formDATA", formData);
          this.challengeService
            .uploadManyFiles(formData, "guesthouse-docs")
            .subscribe((response: any) => {
              console.log("image response", response.results);
              // let docs = this.guestHouseForm.controls.uploadedImage.value;
              console.log('this preview image url', this.previewimageurl);
              let docs: any[] = previousFiles.filter(img => img.length > 1); // Here work is needed              let newdocs = response.results;
              console.log("upload mode docs", docs);
              console.log("indexes", this.indexes);
              // let j = 0;
              // for (let i = 0; i < 4; i++) {
              //   if (response.results[j]) {
              //     //docs[i] = response.results[j];
              //     docs.push(response.results[j]);
              //     j++;
              //   }
              // }
              this.previewimageurl = [];
              this.previewimageurl = this.previewimageurl.concat(docs);
              this.previewimageurl = this.previewimageurl.concat(response.results);
              this.fileInputLabel = undefined;
              data.documents = this.previewimageurl;
              console.log("upload mode data", data);
              this.guestHouseService
                .editGuestHouseData(data, this.guesthouse_id)
                .subscribe(
                  (res: any) => {
                    this.dataTable = res.results;
                    console.log("Lab dataTable::", this.dataTable);
                    this.guestHouseForm.reset();
                    this.previewimageurl=[];
                    this.toastrService.success("Data Updated Successfully!");
                    this.getAllData();
                    this.editMode = false;
                    this.createMode = true;
                  },
                  (error) => {
                    this.inValidMessage = true;
                    this.toastrService.warning("Unable to Update records!");
                  }
                );

              this.uploadMode = false;
            });
        } else {
          console.log("normal mode data", data);
          this.guestHouseService
            .editGuestHouseData(data, this.guesthouse_id)
            .subscribe(
              (res: any) => {
                this.dataTable = res.results;
                console.log("Lab dataTable::", this.dataTable);
                this.guestHouseForm.reset();
                this.previewimageurl=[];
                this.toastrService.success("Data Updated Successfully!");
                this.getAllData();
                this.editMode = false;
                this.createMode = true;
              },
              (error) => {
                this.inValidMessage = true;
                this.toastrService.warning("Unable to Update records!");
              }
            );
        }
      } else {
        this.guestHouseForm.markAllAsTouched();
      }
      this.previewimageurl = [];
      this.images = [];
      this.indexes = [];
    }
  }


  removeImage1(item: any) 
  {


    const updatedValue = this.guestHouseForm.controls["uploadedImage"].value.slice();
  
    // Set the value at index 1 to an empty string
    updatedValue[1] = '';
  
   
    this.guestHouseForm.controls["uploadedImage"].patchValue(updatedValue);     
    this.guestHouseForm.controls["uploadedImage"].setValue(updatedValue.filter(Boolean));
    this.previewimageurl[1] = '';

  }
  removeImage2(item: any) 
  {
  
    const updatedValue = this.guestHouseForm.controls["uploadedImage"].value.slice();
  
    // Set the value at index 1 to an empty string
    updatedValue[2] = '';
  
   
    this.guestHouseForm.controls["uploadedImage"].patchValue(updatedValue);     
    this.guestHouseForm.controls["uploadedImage"].setValue(updatedValue.filter(Boolean));
    this.previewimageurl[2] = '';
  }
  removeImage3(item: any) 
  {
    
   
    const updatedValue = this.guestHouseForm.controls["uploadedImage"].value.slice();
  
    // Set the value at index 1 to an empty string
    updatedValue[3] = '';
  
   
    this.guestHouseForm.controls["uploadedImage"].patchValue(updatedValue);     
    this.guestHouseForm.controls["uploadedImage"].setValue(updatedValue.filter(Boolean));
    this.previewimageurl[3] = '';
  }

  onFileSelect(event, index) {
    this.uploadMode = true;
    this.indexes.push(index);
    console.log("event.target.files", event.target.files);
    const file = event.target.files[0];
    this.files[index] = file; // use spread operator to push all File objects into array
    const filesize = this.files.size;
    const filesizemb = filesize / 1024 ** 2;
    console.log("filesize in mb", filesizemb);
    console.log("file", this.files);

    console.log('551', this.files)
    console.log(this.previewimageurl)

    if (index == 0) {
      this.images[0] = this.files[0]?.name;
      this.files.splice(0, 1, file);
      this.previewimageurl.splice(0, 1, '')
      this.guestHouseForm.get("_img1").setValue(this.files[0]);
    }
    if (index == 1) {
      this.images[1] = this.files[1]?.name;
      this.files.splice(1, 1, file)
      this.previewimageurl.splice(1, 1, '')
      this.guestHouseForm.get("_img2").setValue(this.files[1]);
    }
    if (index == 2) {
      this.images[2] = this.files[2]?.name;
      this.files.splice(2, 1, file)
      this.previewimageurl.splice(2, 1, '')
      this.guestHouseForm.get("_img3").setValue(this.files[2]);
    }
    if (index == 3) {
      this.images[3] = this.files[3]?.name;
      this.files.splice(3, 1, file)
      this.previewimageurl.splice(3, 1, '')
      this.guestHouseForm.get("_img4").setValue(this.files[3]);
    }

    console.log('579', this.files)

    if (filesizemb > 2) {
      this.filesizeerr = true;
    } else {
      this.filesizeerr = false;
    }
    // this.fileInputLabel = file.name;
  }

  gotosection(el: any) {
    el.scrollIntoView({ behaviour: "smooth" });
  }
  cancel() {
    this.guestHouseForm.reset();
    this.editMode = false;
    this.createMode = true;
    this.guestHouseForm.get("stateName").setValue("")
  }

  gotoBooking() {
    this.router.navigate(["/admin/resource-booking"], {
      queryParams: { facility: "guesthouse" },
    });
  }

  deleteData(id: any) {
    this.dialogService
      .open(ShowcaseDialogComponent, {
        context: {
          message: "Are you sure you want to delete this News?",
        },
      })
      .onClose.subscribe((confirmed) => {
        if (confirmed) {
          console.log("deletedata:", id);
          this.guestHouseService.deleteGuestHouseData(id).subscribe(
            (data) => {
              this.getAllData();
              this.toastrService.success("News Deleted Successfully!");
            },
            (error) => {
              this.inValidMessage = true;
              this.toastrService.warning("Unable to Delete News!");
            }
          );
        }
      });
  }

  preview(item: any) {
    console.log("item", item);
    this.modaldata.name = item.name;
    this.modaldata.availableRoomsNo = item.no_of_rooms;
    this.modaldata.foodType = item.food_type;
    this.modaldata.shareType = item.sharing_type;
    this.modaldata.description = item.description;
    this.modaldata.img1 = item.documents.map(doc => {
      return typeof doc === 'string' ? doc : doc.url;
    });
    // this.modaldata.img2 = item.documents[1]?.url ? item.documents[1]?.url : item.documents[1];
    // this.modaldata.img3 = item.documents[2]?.url ? item.documents[2]?.url : item.documents[2];
    // this.modaldata.img4 = item.documents[3]?.url ? item.documents[3]?.url : item.documents[3];
    this.modaldata.incharge_details.name = item.incharge_details.name;
    this.modaldata.incharge_details.designation =
      item.incharge_details.designation;
    this.modaldata.incharge_details.phone = item.incharge_details.phone;
    this.modaldata.incharge_details.email = item.incharge_details.email;
    this.modaldata.address_line1 = item.address_line1;
    this.modaldata.city = item.city;
    this.modaldata.state = item.state;
    this.modaldata.zip_code = item.zip_code;
    this.modaldata.delegatesName =
      item.sections.list[0].target_user_list[0].name;

    this.modaldata.delegatesCharge =
      item.sections.list[0].target_user_list[0].charge;
    this.modaldata.startupName = item.sections.list[0].target_user_list[1].name;
    this.modaldata.startupCharge =
      item.sections.list[0].target_user_list[1].charge;
    this.modaldata.icDelegates = item.sections.list[0].target_user_list[2].name;
    this.modaldata.icDelegatesCharges =
      item.sections.list[0].target_user_list[2].charge;
    this.modaldata.otherCharges =
      item.sections.list[0].target_user_list[3].name;
    this.modaldata.otherName = item.sections.list[0].target_user_list[3].charge;
  }

  // multi select change function
onUserSelectionChange(event:any){ 
  let userType = "INCUBATOR";
  if(event.target.value){
    userType = event.target.value;
  }
  let data = {    
    search_entity: userType,
    };
  this.guestHouseService.filterLab(data).subscribe((res: any) => {
    console.log("pendinggggg.........:", res);
    this.data = res.results;
    let data = [];
      for (var i = 0; i < this.data.length; i++) {
        data.push({
          item_id: this.data[i].user_id,
          item_text: this.data[i].name,
        });
      }
      this.dropdownuser = [...data];
      // console.log('data_domain',data);
    console.log("this is user data", this.dropdownuser)
    
  });
  if(event.target.value === "ALL"){
    this.dropdownuser = [];
  }
}


  changestatus(
    id: string,
    status: string,
    resource_type: string,
    entity: string
  ) {
   
      this.dialogService
        .open(ShowcaseDialogComponent, {
          context: {
            message: "Are you sure do you want to change the status this Venue Space?",
          },
        })
        .onClose.subscribe((confirmed) => {
          if (confirmed) {            
            var data = {
              status: status,
            };
            this.guestHouseService
              .actionFacility(data, id, this.getResourceName(resource_type))
              .subscribe(
                (res: any) => {
                  console.log("change_status", res);                 
                  this.toastrService.success("Successfully updated Status!");
                  // this.getAllData();
                  if(this.filterEnable == false)
                  {
                    console.log("not enable filter")
                    this.getAllData();
                  }
                  const updatedIncubatorIndex = this.data.findIndex(incubator => incubator.id === id);
                  if (updatedIncubatorIndex !== -1) {
                    this.data[updatedIncubatorIndex].status = status;
                  }
                 
                },
                (error) => {                  
                  this.inValidMessage = true;
                  this.toastrService.warning("Unable to Update Status!");
                  
                }
              );
          }
        });
        var htmlElement = document.documentElement;
        if (htmlElement) {
          htmlElement.style.top = '0'; 
          htmlElement.style.position = 'sticky';
        }

  }


  getResourceName(facility: string): string {
    if (facility === "labresource") {
      return "lab";
    } else if (facility === "guesthouseresource") {
      return "guesthouse";
    } else if (facility === "coworkingresource") {
      return "coworking";
    } else if (facility === "equipmentresource") {
      return "equipment";
    }
  }



 //  filter count data
 getCountsByStatus(startups) {
  const statusCounts = {};
  startups.forEach((startup) => {
    const status = startup.status;
    if (status) {
      if (statusCounts.hasOwnProperty(status)) {
        statusCounts[status]++;
      } else {
        statusCounts[status] = 1;
      }
    }
  });   

  return statusCounts;
}
updateStatusCounts(data) {
  const statusCounts = this.getCountsByStatus(data);
  console.log("Status Counts:", statusCounts);
  // Separate the counts
  this.approvedCount = statusCounts["APPROVED"] || 0; //|| this.approvedCount 
  this.rejectedCount = statusCounts["DECLINED"] ||0; //|| this.rejectedCount 
  this.pendingCount = statusCounts["PENDING"] || 0; //||this.pendingCount
  
}
//  filter count data


   // filter
   filterItem(filterValue) {    
    // this.filterMode = false;
    // this.resetMode = true; 
    this.filterEnable = true; 
    this.myArray = [];
    this.p = 1;
    var userList = [];
    var statusList =[];
    var selectedUserType = this.venueFilterForm.get('selectedUserType').value || 'ALL';
    var startDate = this.venueFilterForm.get('start_date').value;
    var endDate = this.venueFilterForm.get('end_date').value;
        
    for (var i = 0; i < this.selectedUser.length; i++) {
      userList.push(this.selectedUser[i].item_id);
    }

    for (var i = 0; i < this.selectedStatus.length; i++) {
      statusList.push(this.selectedStatus[i]);
    }  
   
    if (this.selectedUser.length != 0) {
      this.myArray.push({
        name: "userids",
        value: userList,
      });
    }

    // if (this.selectedStatus.length != 0) {
    //   this.myArray.push({
    //     name: "status",
    //     value: statusList,
    //   });
    // }
    // Add the date filter here
    if (startDate && endDate) {
      this.myArray.push({
        name: "created_at",
        value: [
          {
            "start_date": startDate,
            "end_date": endDate,
          }
        ]
      });
    }

    this.activeFilter = this.selectedStatus.length == 3 ? null : (this.selectedStatus.length > 0 && this.selectedStatus.length < 3 ? this.selectedStatus : null)
      this.filterValue1 = this.selectedStatus      

    console.log("+++++++++myArray",this.myArray);

            
    let data1 = {
     filters: this.myArray, 
     "search_entity":"GUESTHOUSE", 
     "search_usertype_entity":selectedUserType,     
      "page": 0, "page_size": this.totalItems
    };
    console.log('942', this.filterValue1)
    if(this.filterValue == null){      
      this.guestHouseService.getAllGuestHouseData(data1).subscribe((res: any) => {
        // console.log("res myarray___0603:", res);
        this.data = res.results; 
        this.list = [];
        if(this.selectedStatus.length > 0){
          console.log('this.selectedStatus', this.selectedStatus);
          this.selectedStatus.forEach(status => {
            const filterStatus = this.data.filter(item => item.status == status);
            console.log(filterStatus);
            console.log(this.list)
            this.list.push(...filterStatus); 
          })
          console.log('956', this.list)
        }
        else{
          this.list = this.data
        }
        console.log("TEST+++++++++++",res.results)      
        // this.updateStatusCounts(this.list);  
        if(data1.filters.length === 1 && data1.filters[0]['name'] === 'status' && data1.search_usertype_entity === 'ALL'){
          // this.updateStatusCounts(this.data);
        }else{
          this.updateStatusCounts(this.data);
        }
      });   
     
    }

    else{   
    let data = {    
      "page": 0, "page_size": 2984
  }
 
    console.log("data____filterdata", data);
    this.guestHouseService.getAllGuestHouseData(data).subscribe((res: any) => {
      console.log("pendinggggg.........:", res);
      this.data = res.results; 
      // this.list = res.results;
      this.updateStatusCounts(this.data);      

      // this.updateStatusCounts(this.data);      
    });
  }
  }

  resetFilters() {
    // this.resetMode = false;
    // this.filterMode = true;
    this.filterEnable = false;
    // clear the filter criteria object
    this.data = {};
  
      // this.selectsector = "";
       this.selectedUser = [];    
       this.selectedStatus = [];
       this.venueFilterForm.get('selectedUserType').reset();
       this.venueFilterForm.get('selectedUserType').setValue('ALL');
       this.venueFilterForm.get('start_date').reset();
       this.venueFilterForm.get('end_date').reset();
    // reset the filtered data to the original data
      // this.list = this.originalData;
      this.getAllData()
      this.updateStatusCounts(this.list);

  }
   // filter


   // xls download file   
  exportAsXLSX(data):void {
    this.excelService.exportAsExcelFile(data, 'Venue booking');
  }
  downloadlabList(){ 
    this.exportData(this.list);   
  }
 
  exportData(data2: any[]) {    
    const labData = data2.map((data,index) => ({
      // id: d.id,
      'Sl No': index + 1,

      'Center Name': data.userName,
      'Name of the Venue':data.name,
      'Total No. Of Occupancy in the Venue':data.no_of_rooms,
      'Address':data.address_line1,
      'City':data.city,
      'State':data.state,
      'Zip Code':data.zip_code,
      'Other Information':this.stripHtmlTags(data.description),
      'Incharge Name':data.incharge_details.name,
      'Incharge Contact Number':data.incharge_details.phone,
      'Incharge Designation':data.incharge_details.designation,
      'Incharge Email':data.incharge_details.email,
      'Delegate Name':data.sections.list[0].target_user_list[0].name,
      'Delegates Charge':data.sections.list[0].target_user_list[0].charge,
      'StartUp':data.sections.list[0].target_user_list[1].name,
      'Startup Charge':data.sections.list[0].target_user_list[1].charge,
      'IC Delegates/Officials':data.sections.list[0].target_user_list[2].name,
      'IC Delegates Charges':data.sections.list[0].target_user_list[2].charge,

      'Other':data.sections.list[0].target_user_list[2].name,
            'Other Charges': data.sections.list[0].target_user_list[3].name,

      'Status': data.status || '',  
      // 'Updated_at':new Date(data?.updated_at),                       
    }));
    this.exportAsXLSX(labData)
    // this.generateExcelFile(data, "myData.xlsx");
    console.log("Export:", labData);
  }
 
  private stripHtmlTags(html: string): string {
    if (!html) return ''; // Return empty string if input is undefined or null

    // Regular expression to match HTML tags and replace with an empty string
    return html.replace(/(<([^>]+)>)/gi, '');
  }



  // xls download file 


  // countFilter(data)
  // {

  //   this.activeFilter = data;
  //   this.filterEnable = true
  //   this.myArray = [];
  //   // var domains = [];
  //   var userList = [];
  //   var statusList =[]; 
  //   var startDate = this.venueFilterForm.get('start_date').value;
  //   var endDate = this.venueFilterForm.get('end_date').value;
    
  //    // Category filter
  //    for (var i = 0; i < this.selectedUser.length; i++) {
  //     userList.push(this.selectedUser[i].item_id);
  //   }

  //   for (var i = 0; i < this.selectedStatus.length; i++) {
  //     statusList.push(this.selectedStatus[i]);
  //   }  
   
  //   if (this.selectedUser.length != 0) {
  //     this.myArray.push({
  //       name: "userids",
  //       value: userList,
  //     });
  //   }

  //   if (this.selectedStatus.length != 0) {
  //     this.myArray.push({
  //       name: "status",
  //       value: statusList,
  //     });
  //   }
  //   // Add the date filter here
  //   if (startDate && endDate) {
  //     this.myArray.push({
  //       name: "created_at",
  //       value: [
  //         {
  //           "start_date": startDate,
  //           "end_date": endDate,
  //         }
  //       ]
  //     });
  //   }
    
  //   // const filterValue1 = data;   
  //   // let fitlere_data = data;
  //   // this.activeFilter = data;
  //   // this.filterValue1 = data;
  //   // this.onPageChange(1) 
  // //   localStorage.removeItem('status')
  // //   if(this.viewback == 'true')
  // //  {
  // //    const getArray = localStorage.getItem('filterArray');
  // //   const parsedArray = JSON.parse(getArray);
  // //   this.myArray = parsedArray;
  // //    this.myArray = this.myArray.filter(item => item.name !== 'status');
  // //  }
  //   // this.myArray = this.myArray.filter(item => item.name !== 'status');
  //   if(data == 'All')
  //   {
  //     // this.myArray = this.myArray.filter(item => item.name !== 'status');
  //     this.myArray.push({
  //       name: "status",
  //       value: ["PENDING", "DECLINED", "APPROVED"]
  //     });
  //     this.activeFilter = null;
  //     this.selectedStatus = ["PENDING", "DECLINED", "APPROVED"];
  //     // const jsonString = JSON.stringify(this.myArray);
  //     // localStorage.setItem('filterArray', jsonString); 
     
  //   }
  //   else {
       
  //        // this.myArray = this.myArray.filter(item => item.name !== 'status');
      
      
  //        this.myArray.push({
  //         name: "status",
  //         value: [data],
  //       });
  //       this.selectedStatus = [data];
  //       const jsonString = JSON.stringify(this.myArray);
  //       localStorage.setItem('filterArray', jsonString); 
  //     }
  //     const selectedEntity = this.venueFilterForm.get("selectedUserType").value;


  //   let data1 = {
  //     filters: this.myArray,
  //     search_entity: "GUESTHOUSE",
  //     "search_usertype_entity":selectedEntity,
  //     "page": 0, "page_size": 2984
  //    }

  //    this.guestHouseService.getAllGuestHouseData(data1).subscribe((res: any) => {
  //     // console.log("res myarray___0603:", res);
  //     this.data = res.results; 
  //     this.list = res.results;
  //     console.log("TEST+++++++++++",res.results)      
  //     this.updateStatusCounts(this.list);  
  //   });   
  //   // this.p= 1;
  // }

  countFilter(data){
    this.p = 1;
    console.log('data', data);
    if(data === "ALL"){
      console.log('this list', this.data)
      const filterData = ["APPROVED", "PENDING", "DECLINED"]
      const newFilter = this.data.filter((item: any) => item.status == 'APPROVED' || 'REJECTED' || 'PENDING');
      console.log('new filter', newFilter);
      this.selectedStatus = filterData
      this.activeFilter = null;
      this.list = newFilter;
    }
    else{
      console.log('this list', this.data)
      const newFilter = this.data.filter((item: any) => item.status == data);
      console.log('new filter', newFilter);
      this.selectedStatus = [data]
      this.activeFilter = data;
      this.list = newFilter;
    }
  }

  clearImage(data: any){
    console.log(data);
    this.previewimageurl.splice(data, 1, '')
    this.images.splice(data, 1, '');

    console.log(this.previewimageurl)
    // this.files = this.previewimageurl;
  }

  // applyInitialFilters(){
  //   console.log(this.id, this.entity_type);
  //   this.filterEnable = true
  
  //   this.myArray=[];
  //   this.p = 1;
  //   this.venueFilterForm.get('selectedUserType').setValue(this.entity_type)
  //   // var selectedUserType = this.entity_type;
    
  //   let userType = this.entity_type;
  //   let data = {
  //     search_entity: userType,
  //   };
  //   this.guestHouseService.filterLab(data).subscribe((res: any) => {
  //     console.log('response from entity type in redirect', res);
  //     this.data = res.results;
  //     let data = [];
  //       for (var i = 0; i < this.data.length; i++) {
  //         data.push({
  //           item_id: this.data[i].user_id,
  //           item_text: this.data[i].name,
  //         });
  //       }
  //       this.dropdownuser = [...data];
  //       console.log("this is user data", this.dropdownuser);
  
  //       const redirectedSelectedUser = this.dropdownuser.filter(item => item.item_text == this.id);
  //       console.log('redirectedSelectedUser', redirectedSelectedUser);
  //       this.selectedUser = redirectedSelectedUser
  
  //       var userList = this.selectedUser.map(user => user.item_id);
  //       if (userList.length > 0) {
  //         this.myArray.push({
  //           name: "userids",
  //           value: userList,
  //         });
  //       }
  
  //       console.log(this.myArray);
  
  //       this.activeFilter = null;
  //       let payload = {
  //         filters: this.myArray,
  //         "search_entity":"LAB", 
  //         "search_usertype_entity":this.entity_type,
  //         "page": 0,
  //         "page_size": this.totalItems
  //       };
  
  //       this.guestHouseService.getAllGuestHouseData(payload).subscribe((res: any) => {
  //         this.data = res.results;
  //         this.list = [];
  //         if(this.selectedStatus.length > 0){
  //           console.log('this selectedStatus', this.selectedStatus);
  //           this.selectedStatus.forEach(status => {
  //             const filterStatus = this.data.filter(item => item.status == status);
  //             console.log(filterStatus);
  //             console.log(this.list)
  //             this.list.push(...filterStatus);            
  //           })
  //           console.log('955', this.list)
  //         }
  //         else{
  //           this.list = this.data;
  //         }
  //         if(payload.filters.length > 0 && payload.filters[0]['name'] === 'status'){
  //           this.updateStatusCounts(this.data);
  //         }
  //         else{
  //           this.updateStatusCounts(this.data);
  //         }
  //       } )
  //   })
  //   let section = document.querySelector('section');
  //     section.scrollIntoView();
    
  // }
  applyInitialFilters() {
    console.log(this.newid, this.entity_type);
    this.filterEnable = true;
    this.myArray = [];
    this.p = 1;
    this.venueFilterForm.get('selectedUserType').setValue(this.entity_type);
  
    let userType = this.entity_type;
    let data = {
      search_entity: userType,
    };
  
    this.guestHouseService.filterLab(data).subscribe((res: any) => {
      console.log('response from entity type in redirect', res);
      this.data = res.results;
      let data = [];
      for (let i = 0; i < this.data.length; i++) {
        data.push({
          item_id: this.data[i].user_id,
          item_text: this.data[i].name,
        });
      }
      this.dropdownuser = [...data];
      console.log("this is user data", this.dropdownuser);
  
      const redirectedSelectedUser = this.dropdownuser.filter(item => item.item_text == this.newid);
      console.log('redirectedSelectedUser', redirectedSelectedUser);
      this.selectedUser = redirectedSelectedUser;
  
      let userList = this.selectedUser.map(user => user.item_id);
      if (userList.length > 0) {
        this.myArray.push({
          name: "userids",
          value: userList,
        });
      }
  
      console.log(this.myArray, this.totalItems);
  
      this.activeFilter = null;
      let payload = {
        filters: this.myArray,
        search_entity: "GUESTHOUSE",
        search_usertype_entity: this.entity_type,
        page: 0,
        page_size: this.totalItems
      };
  
      // if (this.selectedUser.length > 0) {
        // this.list = []; // Clear the list here before fetching new data
        this.guestHouseService.getAllGuestHouseData(payload).subscribe((res: any) => {
          this.data = res.results;
          this.list = [];
          console.log('this data', this.data);
          
          if (this.selectedStatus.length > 0) {
            console.log('this selectedStatus', this.selectedStatus);
            this.selectedStatus.forEach(status => {
              const filterStatus = this.data.filter(item => item.status == status);
              console.log(filterStatus);
              console.log(this.list);
              this.list.push(...filterStatus);
            });
            console.log('Filtered list:', this.list);
          } else {
            this.list = this.data;
          }
  
          if (payload.filters.length > 0 && payload.filters[0]['name'] === 'status') {
            this.updateStatusCounts(this.data);
          } else {
            this.updateStatusCounts(this.data);
          }
        });
      // } else {
        // this.getAllData(); // Call getAllData() when selectedUser.length === 0
      // }
  
      console.log('selected entity data', this.list);
    });
  
    let section = document.querySelector('section');
    if (section) {
      section.scrollIntoView();
    }
  }
}

