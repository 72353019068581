import { Component, OnInit } from '@angular/core';
import * as XLSX from "xlsx";
import { AcceleratorService } from '../../services/accelerator.service';
import { NbDialogService, NbToastrService } from "@nebular/theme";
import { ExportToCsv } from "export-to-csv";
import { ActivatedRoute, Router } from "@angular/router";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ChallengeService } from "../../services/challenge.service";
import * as _ from "lodash";
import { IDropdownSettings } from "ng-multiselect-dropdown";
import { states } from "../../../assets/js/states";
import { MatDialog } from "@angular/material/dialog";
import { CommondialogComponent } from "../../@core/miscellaneous/commondialog/commondialog.component";
import { ExcelService } from "../../services/excel.service";
import { StateService } from "../../services/state.service";
const styles = {
  header: {
    fontSize: 18,
    bold: true,
  },
  subheader: {
    fontSize: 14,
    bold: true,
  },
  body: {
    fontSize: 12,
  },
};

@Component({
  selector: 'ngx-accelerator',
  templateUrl: './accelerator.component.html',
  styleUrls: ['./accelerator.component.scss']
})
export class AcceleratorComponent implements OnInit {

  data: any;
  items = [{ title: "PDF" }, { title: "EXCEL" }];
  list: any = [];
  modalUpdateForm: FormGroup;
  action: { status: string };
  inValidMessage: boolean;
  alldomains: any;
  groupbydomains: any;
  dropdownList = [];
  sectors: any = [];
  dropdownList1 = [];
  tempdata = [];
  selectedItems1 = [];
  dropdownSettings: IDropdownSettings = {};
  dropdownSettings_t: IDropdownSettings = {};
  dropdownSettings_s: IDropdownSettings = {};
  myArray: any[] = [];
  itemName: string;
  itemCategory: string;
  selectcity: string = "";
  selectstate: any = [];
  selectdomain: any = [];
  selectsector: any = [];
  selectstatus: any = [];
  selectedItems: any[];
  selectedStates: any[];
  // dropdownstates: any[] = [
  //   'Karnataka','UP','Telengana', 'Andhra Pradesh'
  // ];
  dropdownstates: any = [];
  selectedStatus: any[];
  dropdownstatus: any[] = ["PENDING", "REJECTED", "APPROVED"];
  originalData: any[];
  public domain = [];
  public sector = [];
  filteredlist = [];
  filterMode: boolean = true;
  resetMode: boolean = false;
  p: number = 1;
  filterValue: any;
  mat_data: string;
  totalItems: number;
  StartupList:any;
  paginationEnabled: boolean = true;
  allStates: any;
  approvedCount: number = 0;
  rejectedCount: number = 0;
  pendingCount: number = 0;
  pageSize: number = 25; 
  loading: boolean = true; 
  activeFilter: string | null = null; 
  filterValue1:any;
  viewback: any;

  constructor(
    private AcceletorService: AcceleratorService,
    private toastrService: NbToastrService,
    private router: Router,
    private fb: FormBuilder,
    private challengeService: ChallengeService,
    private _route:ActivatedRoute,
    private dialogService: NbDialogService,
    private dialog: MatDialog,
    private excelService:ExcelService,
    private statesservice : StateService


  ) {
    this.filterValue = this._route.snapshot.queryParamMap.get('filter');
    // if (localStorage.getItem('status') ) {
    //   // If it exists, assign its value to this.filterValue
    //   this.filterValue = localStorage.getItem('status');
    // }  
    this.activeFilter = this.filterValue;
    if(localStorage.getItem('viewback'))
    {
      this.viewback = localStorage.getItem('viewback');
    }
   
    console.log("filtervalue", this.filterValue);
  }

  ngOnInit(): void {
    // console.log("states_____from____js", states);
    this.selectedItems = [];
    this.selectedStates = [];
    this.selectedStatus = [];
    this.getStates()
    // if(this.filterValue != null) {
    //   this.filterItem(this.filterValue);
    //   this.filterValue = null;
    // }
    // else{
    //   this.listAllAccelerators();
     
    // }
    // this.getAllStartUpData();
    this.getDomains();
    if (this.viewback == 'true') {          
      this.backfilter();   
      if(localStorage.getItem('countdata'))
      {
        const storedJsonString = localStorage.getItem('countdata');
        const storedObject = JSON.parse(storedJsonString);
        console.log("count data is==>", storedObject);
        console.log(storedObject.approvedData)
        this.approvedCount = storedObject.approvedData;
        this.rejectedCount = storedObject.rejectedData;
        this.pendingCount = storedObject.pendingData;
       
  
      }  
      // localStorage.removeItem('viewback');
    } else if (this.filterValue != null) {
     
      this.filterItem(this.filterValue);
      localStorage.removeItem('viewback');
      localStorage.removeItem('filterArray');
      this.selectedStates = [];
      this.selectedStatus = this.filterValue;
      this.selectcity = '';
      this.selectedItems = [];
      this.activeFilter = this.filterValue;
      this.viewback = 'true'
      
      

    } else {
      
      this.listAllAccelerators();
    }

    this.modalUpdateForm = this.fb.group({
      modalStartUpName: [""],
      modalDocumentName: [""],
    });

    // this.selectedItems = [];
    this.dropdownList = [];
    // this.dropdownstates = states;
    // this.selectedStates = [];
    // this.selectedStatus = [];
    this.selectedItems1 = [];
    this.filteredlist = [];

    this.dropdownSettings = {
      singleSelection: false,
      idField: "item_id",
      textField: "item_text",
      selectAllText: "Select All",
      unSelectAllText: "UnSelect All",
      // itemsShowLimit: 3
      allowSearchFilter: true,
    };
    this.dropdownSettings_s = {
      singleSelection: false,
      idField: "item_id",
      textField: "item_text",
      selectAllText: "Select All",
      unSelectAllText: "UnSelect All",
      // itemsShowLimit: 3
      allowSearchFilter: true,
    };
    this.dropdownSettings_t = {
      singleSelection: true,
      idField: "item_id",
      textField: "item_text",
      selectAllText: "Select All",
      unSelectAllText: "UnSelect All",
      // itemsShowLimit: 3
      allowSearchFilter: true,
    };
    // this.exportData(this.startupdata)
    // const startupDataArray = this.startupdata1; // Array containing multiple startupData objects
    // this.exportData(startupDataArray);

      // pagination page redirection code
      this._route.queryParams.subscribe((params) => {
        // Use the 'page' parameter from the URL or default to 1
        this.p = +params['page'] || 1;
        // Load data for the current page
        this.loadData(this.p);
      });
       // pagination page redirection code
  }


  ngOnDestroy(): void{
    // remove style for ui break on scroll
    var htmlElement = document.documentElement;
    if (htmlElement) {
      htmlElement.style.top = ''; 
      htmlElement.style.position = '';
    }
   
      // remove style for ui break on scroll
      localStorage.removeItem('viewback');
 }

  //  pagination page redirection code
onPageChange(page: number): void {
  // Update the current page and navigate to the new URL
  this.p = page;
  this.router.navigate([], {
    relativeTo: this._route,
    queryParams: { page: page },
    queryParamsHandling: 'merge',
  });
 
  this.loadData(page);
}
private loadData(page: number): void {
  // Implement your logic to load data based on the page
  console.log('Loading data for page', page);
}
//  pagination page redirection code

  exportAsXLSX(data):void {
    this.excelService.exportAsExcelFile(data, 'Accelerator Report');
  }

  // state
  getStates() {
    this.statesservice.getallstates().subscribe((res: any) => {
      this.allStates = res.results;
      console.log("all states =>", this.allStates);
      this.dropdownstates = this.allStates
     
   
    });
  }
  // state

  listAllAccelerators() {
   
    let data={
      search_entity: "ACCELERATOR",
      "page": 0, "page_size": 99999
    }
    this.loading = true;
    this.AcceletorService.getacceleratorsList(data).subscribe((res: any) => {
      this.data = res.results;     
      this.StartupList=res.results;
      console.log("acceletor::", this.data);
     
 
      this.originalData = this.data;
    

      this.totalItems = +res.total_search_results_count;
      console.log("334___________", this.totalItems);
      
      this.updateStatusCounts(this.data);
      this.loading = false;
      this.onPageChange(1) 

    });
  }
  downloadStartupList(){
    // const startupDataArray = this.originalData; // Array containing multiple startupData objects
    this.exportData(this.data);
    console.log('this.data', this.data)
    // this.exportAsXLSX(this.data)
  }





  
  
  exportData(dataArray: any[]) {
    const formatDate = (date) => {
      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
      const year = date.getFullYear();
      return `${day}/${month}/${year}`;
    };
    
    const formatTime = (date) => {
      return date.toLocaleTimeString('en-US', {
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: true
      });
    };
    const data = dataArray.map((d) => {
    const result: any = {
      Name: d.name ? d.name : '-',
      'Affiliate Name': d.affiliate_name ? d.affiliate_name : '-',
      Domain: d.domain ? ((d.domain.length > 0) ? d.domain.join(', ') : '-') : '-',
      Sector: d.sector ? (Array.isArray(d.sector) ? d.sector.join(', ') : d.sector) : '-',
      City: d.city ? d.city : '-',
      State: d.state ? d.state : '-',
      Country: d.country ? d.country : '-',
      Website: d.website_url ? d.website_url : '-',
      'Full Description': d.full_description ? d.full_description : '-',
      Status: d.status ? d.status : '-',
      // Id : d.id ? d.id : '-',
      // Documents: d.documents ? (Array.isArray(d.documents) ? d.documents.map(info => `${info.file_name} URL: ${info.url}`).join('\n') : '-') : '-',

      'CEO Firstname': d?.team_info ? d.team_info?.ceoFirstName : '-', 
      'CEO Lastname' : d?.team_info ? d.team_info?.ceoLastName : '-',
      'CEO Phone No.': d?.team_info ? d.team_info?.ceoPhoneNo : '-',
      'CEO Email' : d?.team_info ? d.team_info?.ceoEmail : '-',
      'Team Size': Array.isArray(d?.team_info?.team_array) ? d?.team_info?.team_array.length : '-',
      'Team Details':Array.isArray(d?.team_info?.team_array) ? d?.team_info?.team_array.map(info => `First Name=${info.teamFirstName},Last Name= ${info.teamLastName},Mobile= ${info.teamPhoneNo},Email=${info.teamEmail}`).join('\n') : '-',
      // Sucess_stories: d.success_stories,
      // 'Social Info': d?.social_info ? `Facebook=${d.social_info.facebook ? d.social_info.facebook : '- '}\n Instagram=${d.social_info.instagram ? d.social_info.instagram : '-'}\n Twitter=${d.social_info.twitter ? d.social_info.twitter : '-'}\n LinkedIn=${d.social_info.linkedIn ? d.social_info.linkedIn : '-'}\n Youtube=${d.social_info.youtube ? d.social_info.youtube : '-'}\nKoo=${d.social_info.koo ? d.social_info.koo : '-'}` : '-',
      Facebook: d?.social_info?.facebook ? d.social_info.facebook : '-',
      Instagram: d?.social_info?.instagram ? d.social_info.instagram : '-',
      Twitter: d?.social_info?.twitter ? d.social_info.twitter : '-',
      LinkedIn: d?.social_info?.linkedIn ? d.social_info.linkedIn : '-',
      Youtube: d?.social_info?.youtube ? d.social_info.youtube : '-',
      // Koo: d?.social_info?.koo ? d.social_info.koo : '-',
    //  'Profile Email': d?.team_info?.ceoEmail,
     'Login Email':d?.login_email ? d?.login_email : '-',
     'Created At': d.created_at ? new Date(d.created_at) : '-',
    //  'Updated At':new Date(d?.updated_at),
    'Last Login Date':d?.login_info ? formatDate(new Date(d.login_info.last_login_time) ) : '-',
      'Last Login Time':d?.login_info ? formatTime(new Date(d.login_info.last_login_time)):'-'
  };

  if (this.activeFilter === 'APPROVED') {
    result.ApprovedBy = d.approved_by ? d.approved_by : '-';
  }else if(this.activeFilter === 'REJECTED'){
    result.RejectedBy = d.approved_by ? d.approved_by : '-';
  }else if(this.activeFilter !== 'PENDING'){
    result['Approved/Rejected By'] = d.approved_by ? d.approved_by : '-';
  }
  return result;
  }
  );
    this.exportAsXLSX(data)
    // this.generateExcelFile(data, "myData.xlsx");
    console.log("Export:", data);
  }
  
 
  downloadFile(d) {
    const options = {
      fieldSeparator: ",",
      quoteStrings: '"',
      decimalSeparator: ".",
      showLabels: true,
      showTitle: true,
      title: "Applications",
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: true,
      filename: "applications",
      headers: ["Name", "Domain", "Sector", "Location", "Description", "Stage"],
    };
    const csvdata = [
      {
        Name: d.name,
        Domain: d.domain,
        City: d.city,
        Sector: d.sector,
        Description: d.short_description,
        Stage: d.startup_stage,
      },
    ];
    const csvExporter = new ExportToCsv(options);

    csvExporter.generateCsv(csvdata);
  }

  editData(data) {
    this.data = data;
    console.log("edit statupDATA:", this.data);
    this.modalUpdateForm.controls["modalStartUpName"].setValue(data.name);
  }
  updatestartupModel(id) {
    let data = {};
    this.AcceletorService.editaccelerator(data, id).subscribe(
      (res: any) => {
        console.log("statSucces:", res);
        this.toastrService.success("Data Updated Successfully!");
      },
      (error) => {
        this.inValidMessage = true;
        this.toastrService.warning("Unable to Update records!");
      }
    );
  }

  changestatus(id: string, value: string) {
    // localStorage.setItem('myData', 'Startup');
    this.mat_data = value;
    if(this.mat_data=='REJECTED'){
      this.mat_data= 'Reject'
    }else if(this.mat_data=="APPROVED"){
      this.mat_data= 'Approve'
    }
    const dialogRef=this.dialog.open(CommondialogComponent,{
          data: { status: this.mat_data, name: 'accelerators' }
        })
        dialogRef.afterClosed().subscribe(result => {
          if(result==true){
            var data = {
              status: value,
            };
            this.AcceletorService.actionaccelerator(data, id).subscribe(
              (res: any) => {
                console.log("change_status", res);              
                // this.listAllAccelerators();
                localStorage.removeItem('myData');
                this.toastrService.success("Successfully updated Status!");
                
              },
              (error) => {
                this.inValidMessage = true;
                this.toastrService.warning("Unable to Update Status!");
              }
            );
          }
        })
  }

  getDomains() {
    
    this.challengeService.getalldomains().subscribe((res: any) => {
      this.alldomains = res.results;
      let data = [];
      for (var i = 0; i < this.alldomains.length; i++) {
        
        data.push({
          item_id: i + 1,
          item_text: this.alldomains[i].name,
        
         
        });
       
      }
    //  console.log("data domaing ",data)
      this.dropdownList = [...data];
      // console.log("data dropdownList ", this.dropdownList)
      // console.log('data_domain',data);
      this.groupbydomains = _.groupBy(this.alldomains, "name");
      console.log("group domains", this.groupbydomains);
    });
  }
  async onItemSelectDomain(event) {
    console.log("onItemSelect", this.groupbydomains, event);
    console.log(
      "getsector",
      this.groupbydomains[`${event.item_text}`][0]["sectors"]
    );
    await this.groupbysectors(event);
    console.log("sectors", this.sectors);
    for (var i = 0; i < this.sectors.length; i++) {
      this.tempdata.push({
        item_id: i + 1,
        item_text: this.sectors[i].name,
      });
    }
    this.dropdownList1 = [...this.tempdata];
    console.log("sectors_dropdown", this.dropdownList1);
  }

  async onItemDeSelectDomain(event) {
    console.log("event", event);
  }
  async groupbysectors(event) {
    return new Promise((resolve, reject) => {
      this.challengeService.setSectorData(
        this.groupbydomains[`${event.item_text}`][0]["sectors"]
      );
      this.sectors.push(
        ...this.groupbydomains[`${event.item_text}`][0]["sectors"]
      );
      resolve(this.sectors);
    });
  }

  backfilter(){   
    if(this.viewback == 'true')
    {
      const getArray = localStorage.getItem('filterArray');
      const parsedArray = JSON.parse(getArray);
      this.myArray = parsedArray;
      const stateValue = parsedArray.find(item => item.name === 'state')?.value;
      const statusValue = parsedArray.find(item => item.name === 'status')?.value;
      const cityvalue = parsedArray.find(item => item.name === 'city')?.value;
      const domainvalue = parsedArray.find(item => item.name === 'domain')?.value;      
      this.selectedStates = stateValue;
      this.selectedStatus = statusValue;
      this.selectcity = cityvalue
      this.selectedItems = domainvalue;
     
    }
    
  
      if(localStorage.getItem('status'))
      {
        this.activeFilter = localStorage.getItem('status');
      }
  
     
  
  
      let data = {
        filters: this.myArray,
        search_entity: "ACCELERATOR",
        "page": 0, "page_size": 2984
  
      };
  
      console.log("data____0603", data);
   
      if(this.filterValue == null){
        this.AcceletorService.filteraccelerator(data).subscribe((res: any) => {
        // console.log("res myarray___0603:", res);
        this.data = res.results;        
        // this.updateStatusCounts(this.data);
        this.loading = false;
      });
     
    
  }
}


  filterItem(filterValue) {
    // this.filterMode = false;
    // this.resetMode = true;   
    if(filterValue == 'null')
    {
     this.activeFilter = null;
    } 
    
    this.myArray = [];
    var domains = [];
    localStorage.removeItem('status');
   
    
    if(this.selectdomain && this.selectdomain.length > 0)
    {
      for (var i = 0; i < this.selectdomain.length; i++) {
        domains.push(this.selectdomain[i].item_text);
      }
      if (this.selectdomain.length != 0) {
        this.myArray.push({
          name: "domain",
          value: domains,
        });
      }
    }
    var sector = [];
    // for (var i = 0; i < this.selectsector.length; i++) {
    //   sector.push(this.selectsector[i].item_text);
    // }
    // console.log("select states", this.selectedStates);
    // var myItem =[{ name: "city", value: this.selectcity}]
    // var myItem1= [{ name: "state", value: [this.selectedStates] }]
    // var myItem2= [{ name: "domain", value: domains }]
    // var myItem3= [{ name: "sector", value: sector }]
    // var myItem4= [{ name: "status", value: [...this.selectstatus] }]
    // this.myArray.push(...myItem, ...myItem1, ...myItem2, ...myItem3, ...myItem4)
    // console.log("array:",this.myArray);

    if (this.selectcity !== "") {
      this.myArray.push({
        name: "city",
        value: this.selectcity,
      });
      console.log("selectcity:", this.myArray);
    }
    if (this.selectstate && this.selectstate.length > 0) {
      this.myArray.push({
        name: "state",
        value: this.selectstate,
      });
    }
  
    // if (this.selectdomain.length != 0) {
    //   this.myArray.push({
    //     name: "domain",
    //     value: domains,
    //   });
      
    // }
  
    // if (this.selectsector.length != 0) {
    //   this.myArray.push({
    //     name: "sector",
    //     value: sector,
    //   });
    // }
    if (this.selectstatus.length != 0) {
      this.myArray.push({
        name: "status",
        value: this.selectstatus,
      });
      console.log("selectcity1223:", this.myArray);
      this.activeFilter = this.selectstatus;
      this.filterValue1 = this.selectstatus
    }

    // if (this.selectstatus.length == 0) {
    //   this.myArray.push({
    //     name: "status",
    //     value: filterValue,
    //   });
      
      
    //   console.log("filter value by stats", this.myArray);
    // } 
    const jsonString = JSON.stringify(this.myArray);
    localStorage.setItem('filterArray', jsonString); 
    let data = {
      filters: this.myArray,
      search_entity: "ACCELERATOR",
      "page": 0, "page_size": 2984

    };

    console.log("data____0603", data);
 
    if(this.filterValue == null){
      this.AcceletorService.filteraccelerator(data).subscribe((res: any) => {
        // console.log("res myarray___0603:", res);
       
        this.data = res.results;
        //console.log("filter result" + res.result.state)
        this.onPageChange(1);
        if(data.filters.length > 0 && data.filters[0]['name'] === 'status'){
          // this.updateStatusCounts(this.data);
        }else{
          this.updateStatusCounts(this.data);
        } 
        const statusCount = {
          approvedData: this.approvedCount,
          rejectedData: this.rejectedCount,
          pendingData: this.pendingCount
        };
        const countstring = JSON.stringify(statusCount);
        localStorage.setItem('countdata', countstring);
      

    
      });
     
    }

    else{
   
    let data = {
      "filters": [
          {
              "name": "status",
              "value": filterValue
          }
      ],
      "search_entity": "ACCELERATOR",
      "page": 0, "page_size": 2984

  }
    console.log("data____filterdata", data);
    this.AcceletorService.filteraccelerator(data).subscribe((res: any) => {
      console.log("pendinggggg.........:", res);
      this.data = res.results;
      this.updateStatusCounts(this.data);
      this.loading = false;
    });
  }
  }

  countFilter(data)
  {

    this.myArray = [];
    var domains = [];

    
    
    if(this.selectdomain && this.selectdomain.length > 0)
    {
      for (var i = 0; i < this.selectdomain.length; i++) {
        domains.push(this.selectdomain[i].item_text);
      }
      if (this.selectdomain.length != 0) {
        this.myArray.push({
          name: "domain",
          value: domains,
        });
      }
    }
    var sector = [];
    

    if (this.selectcity !== "") {
      this.myArray.push({
        name: "city",
        value: this.selectcity,
      });
      console.log("selectcity:", this.myArray);
    }
    if (this.selectstate && this.selectstate.length > 0)  {
      this.myArray.push({
        name: "state",
        value: this.selectstate,
      });
    }
    // if (this.selectdomain.length != 0) {
    //   this.myArray.push({
    //     name: "domain",
    //     value: domains,
    //   });
    // }
    // if (this.selectsector.length != 0) {
    //   this.myArray.push({
    //     name: "sector",
    //     value: sector,
    //   });
    // }
    this.myArray.push({
      name: "status",
      value: data,
    });
    let fitlere_data = data;
    this.activeFilter = data;
    this.filterValue1 = data;   
    // this.onPageChange(1) 
    localStorage.removeItem('status');

    if(this.viewback == 'true')
    {
      const getArray = localStorage.getItem('filterArray');
     const parsedArray = JSON.parse(getArray);
     this.myArray = parsedArray;
      this.myArray = this.myArray.filter(item => item.name !== 'status');
    }
     // this.myArray = this.myArray.filter(item => item.name !== 'status');
     if(data == 'All')
     {
       // this.myArray = this.myArray.filter(item => item.name !== 'status');
       this.myArray.push({
         name: "status",
         value: ["PENDING", "REJECTED", "APPROVED"]
       });
       this.activeFilter = null;
       this.selectedStatus = ["PENDING", "REJECTED", "APPROVED"];
       const jsonString = JSON.stringify(this.myArray);
       localStorage.setItem('filterArray', jsonString); 
      
     }
     else {
       
         // this.myArray = this.myArray.filter(item => item.name !== 'status');
      
      
       this.myArray.push({
         name: "status",
         value: [data],
       });
       this.selectedStatus = [data];
       const jsonString = JSON.stringify(this.myArray);
       localStorage.setItem('filterArray', jsonString); 
     }
   
    
     
     const jsonString = JSON.stringify(this.myArray);
     localStorage.setItem('filterArray', jsonString); 

    let data1 = {
      filters: this.myArray,
      "search_entity": "ACCELERATOR",
      "page": 0, "page_size": 5000

  }
  this.AcceletorService.filteraccelerator(data1).subscribe((res: any) => {     
     
      this.data = res.results;
      console.log('count status data is ', this.data)
      // this.updateStatusCounts(this.data);
      this.onPageChange(1) 
      const statusCount = {
        approvedData: this.approvedCount,
        rejectedData: this.rejectedCount,
        pendingData: this.pendingCount
      };
      const countstring = JSON.stringify(statusCount);
      localStorage.setItem('countdata', countstring);
    });
    // this.p= 1;
  }
  countAllFilter()
  {
    this.activeFilter = null;
    localStorage.removeItem('status');
    this.onPageChange(1) 
    this.resetFilters()
    this.listAllAccelerators();
     this.updateStatusCounts(this.data);
  }

  resetFilters() {
    //debugger;
    this.resetMode = false;
    this.filterMode = true;

    // clear the filter criteria object
    this.data = {};
      this.selectcity = "";
      this.selectstate = "";
      this.selectdomain = "";
      // this.selectsector = "";
      this.selectstatus = "";
    // reset the filtered data to the original data
    // this.data = this.originalData;
    // this.paginationEnabled = true;    
    localStorage.removeItem('status');
    this.activeFilter = null;
    this.onPageChange(1) 
    localStorage.removeItem('filterArray');    
    localStorage.removeItem('viewback')
    this.viewback = 'false'; 
    this.listAllAccelerators();
    this.updateStatusCounts(this.data);
  }
  preview(d) {
    console.log("pCheck::", d);
    this.router.navigateByUrl(`/admin/acceleratortemp/${d}`);
    if(this.filterValue1.length > 0)
    {
      localStorage.setItem('status',this.filterValue1)
      console.log("filte value is ==> ", this.filterValue1)
    }
  }


  getCountsByStatus(startups) {
    const statusCounts = {};
    startups.forEach((startup) => {
      const status = startup.status;
      if (status) {
        if (statusCounts.hasOwnProperty(status)) {
          statusCounts[status]++;
        } else {
          statusCounts[status] = 1;
        }
      }
    });   

    return statusCounts;
  }

  updateStatusCounts(data) {
    const statusCounts = this.getCountsByStatus(data);
    console.log("Status Counts:", statusCounts);
    // Separate the counts
    this.approvedCount = statusCounts["APPROVED"] || 0;
    this.rejectedCount = statusCounts["REJECTED"] || 0;
    this.pendingCount = statusCounts["PENDING"] || 0;
    
  }

}
