import { Injectable } from '@angular/core';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Injectable()
export class ExcelService {

    constructor() { }



    public exportAsExcelFile(json: any[], excelFileName: string): void {
        const worksheet: XLSX.WorkSheet = {} as XLSX.WorkSheet;

        // Extract column headings from the first object in the JSON array
        const columnHeadings: string[] = Object.keys(json[0]);

        // Convert data to a 2D array
        const dataArray: any[][] = [];

        // Add column headings as the first row in the data array
        dataArray.push(columnHeadings);

        // Add data rows to the data array
        json.forEach((row) => {
            const rowData: any[] = [];
            for (const key in row) {
                if (row.hasOwnProperty(key)) {
                    rowData.push(row[key]);
                }
            }
            dataArray.push(rowData);
        });

        // Add the data array to the worksheet
        XLSX.utils.sheet_add_aoa(worksheet, dataArray, { origin: 0 });

        // Adjusting column widths based on content length
        const columnWidths: XLSX.ColInfo[] = [];
        const cellRange = XLSX.utils.decode_range(worksheet['!ref']);
        for (let C = cellRange.s.c; C <= cellRange.e.c; ++C) {
            const columnValues: any[] = [];
            for (let R = cellRange.s.r; R <= cellRange.e.r; ++R) {
                const cellAddress = { c: C, r: R };
                const cell = worksheet[XLSX.utils.encode_cell(cellAddress)];
                if (cell && cell.v) {
                    columnValues.push(cell.v.toString());
                }
            }
            const maxLength = Math.max(...columnValues.map((value) => value.length));
            const width = maxLength > 20 ? 20 : maxLength; // Adjust the maximum column width as desired
            columnWidths.push({ wch: width });
        }
        worksheet['!cols'] = columnWidths;

        // Adjusting cell heights based on content
        // const cellHeightMap: { [key: string]: number } = {};
        // for (let R = cellRange.s.r; R <= cellRange.e.r; ++R) {
        //   for (let C = cellRange.s.c; C <= cellRange.e.c; ++C) {
        //     const cellAddress = { c: C, r: R };
        //     const cell = worksheet[XLSX.utils.encode_cell(cellAddress)];
        //     if (cell && cell.v) {
        //       const lines = cell.v.toString().split('\n');
        //       const lineHeight = 15; // Adjust the line height as desired
        //       const cellHeight = Math.max(lines.length * lineHeight, 25); // Adjust the minimum cell height as desired
        //       const key = `${C}-${R}`;
        //       if (!cellHeightMap[key] || cellHeight > cellHeightMap[key]) {
        //         cellHeightMap[key] = cellHeight;
        //       }
        //     }
        //   }
        // }
        // worksheet['!rows'] = Object.keys(cellHeightMap).map((key) => ({
        //   r: parseInt(key.split('-')[1], 10),
        //   hpx: cellHeightMap[key],
        //   hidden: false,
        // }));
        // Adjusting cell heights based on fixed height
        const cellHeightMap: { [key: string]: number } = {};
        for (let R = cellRange.s.r; R <= cellRange.e.r; ++R) {
            for (let C = cellRange.s.c; C <= cellRange.e.c; ++C) {
                const cellAddress = { c: C, r: R };
                const cell = worksheet[XLSX.utils.encode_cell(cellAddress)];
                if (cell && cell.v) {
                    const key = `${C}-${R}`;
                    if (!cellHeightMap[key] || 20 > cellHeightMap[key]) {
                        cellHeightMap[key] = 30; // Set a fixed height of 30 pixels
                    }
                }
            }
        }

        // Generate an array of row objects with the fixed height
        worksheet['!rows'] = Object.keys(cellHeightMap).map((key) => ({
            r: parseInt(key.split('-')[1], 10), // Extract the row index from the key
            hpx: 30, // Set a fixed height of 30 pixels
            hidden: false, // Set the hidden property to false to ensure the row is visible
        }));


        const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
        const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

        this.saveAsExcelFile(excelBuffer, excelFileName);
    }

    public exportExcelFileWithSheet(json: {key:string, value:any}, excelFileName: string): void { 
        const workbook = XLSX.utils.book_new();
        for (const [key, value] of Object.entries(json)) {
            const columnHeadings: string[] = Object.keys(value[0]);
            const dataArray: any[][] = [];
            dataArray.push(columnHeadings);
            value.forEach((row) => {
                const rowData: any[] = [];
                for (const key in row) {
                    if (row.hasOwnProperty(key)) {
                        rowData.push(row[key]);
                    }
                }
                dataArray.push(rowData);
            });
            let worksheet: XLSX.WorkSheet = {} as XLSX.WorkSheet;
            XLSX.utils.sheet_add_aoa(worksheet, dataArray, { origin: 0 });
            worksheet = this.adjustCellWidthHeight(worksheet);
            XLSX.utils.book_append_sheet(workbook, worksheet, key);
        }
        XLSX.writeFileXLSX(workbook, excelFileName);
    }


    adjustCellWidthHeight(worksheet) {
        // Adjusting column widths based on content length
        const columnWidths: XLSX.ColInfo[] = [];
        const cellRange = XLSX.utils.decode_range(worksheet['!ref']);
        for (let C = cellRange.s.c; C <= cellRange.e.c; ++C) {
            const columnValues: any[] = [];
            for (let R = cellRange.s.r; R <= cellRange.e.r; ++R) {
                const cellAddress = { c: C, r: R };
                const cell = worksheet[XLSX.utils.encode_cell(cellAddress)];
                if (cell && cell.v) {
                    columnValues.push(cell.v.toString());
                }
            }
            const maxLength = Math.max(...columnValues.map((value) => value.length));
            const width = maxLength > 20 ? 20 : maxLength; // Adjust the maximum column width as desired
            columnWidths.push({ wch: width });
        }
        worksheet['!cols'] = columnWidths;

        const cellHeightMap: { [key: string]: number } = {};
        for (let R = cellRange.s.r; R <= cellRange.e.r; ++R) {
            for (let C = cellRange.s.c; C <= cellRange.e.c; ++C) {
                const cellAddress = { c: C, r: R };
                const cell = worksheet[XLSX.utils.encode_cell(cellAddress)];
                if (cell && cell.v) {
                    const key = `${C}-${R}`;
                    if (!cellHeightMap[key] || 20 > cellHeightMap[key]) {
                        cellHeightMap[key] = 30; // Set a fixed height of 30 pixels
                    }
                }
            }
        }

        // Generate an array of row objects with the fixed height
        worksheet['!rows'] = Object.keys(cellHeightMap).map((key) => ({
            r: parseInt(key.split('-')[1], 10), // Extract the row index from the key
            hpx: 30, // Set a fixed height of 30 pixels
            hidden: false, // Set the hidden property to false to ensure the row is visible
        }));

        return worksheet;
    }








    //   exportAsExcelFile(json: any[], excelFileName: string): void {
    //     const worksheet: XLSX.WorkSheet = {} as XLSX.WorkSheet;

    //     // Extract column headings from the first object in the JSON array
    //     const columnHeadings: string[] = Object.keys(json[0]);

    //     // Convert data to a 2D array
    //     const dataArray: any[][] = [];

    //     // Add column headings as the first row in the data array
    //     dataArray.push(columnHeadings);

    //     // Iterate over each object in the JSON array
    //     json.forEach((row) => {
    //       // Iterate over each sub-object in the current row object
    //       row.opportunity_info?.forEach((subObject) => {
    //         const rowData: any[] = [];
    //         // Iterate over the keys in the sub-object
    //         for (const key in subObject) {
    //           if (subObject.hasOwnProperty(key)) {
    //             rowData.push(subObject[key]);
    //           }
    //         }
    //         dataArray.push(rowData);
    //       });
    //     });

    //     // Add the data array to the worksheet
    //     XLSX.utils.sheet_add_aoa(worksheet, dataArray, { origin: 0 });

    //     // ... Rest of the code for adjusting column widths, cell heights, etc.
    //     // ...

    //     // Adjusting column widths based on content length
    //     const columnWidths: XLSX.ColInfo[] = [];
    //     const cellRange = XLSX.utils.decode_range(worksheet['!ref']);
    //     for (let C = cellRange.s.c; C <= cellRange.e.c; ++C) {
    //       const columnValues: any[] = [];
    //       for (let R = cellRange.s.r; R <= cellRange.e.r; ++R) {
    //         const cellAddress = { c: C, r: R };
    //         const cell = worksheet[XLSX.utils.encode_cell(cellAddress)];
    //         if (cell && cell.v) {
    //           columnValues.push(cell.v.toString());
    //         }
    //       }
    //       const maxLength = Math.max(...columnValues.map((value) => value.length));
    //       const width = maxLength > 20 ? 20 : maxLength; // Adjust the maximum column width as desired
    //       columnWidths.push({ wch: width });
    //     }
    //     worksheet['!cols'] = columnWidths;

    //     // Adjusting cell heights based on content
    //     // const cellHeightMap: { [key: string]: number } = {};
    //     // for (let R = cellRange.s.r; R <= cellRange.e.r; ++R) {
    //     //   for (let C = cellRange.s.c; C <= cellRange.e.c; ++C) {
    //     //     const cellAddress = { c: C, r: R };
    //     //     const cell = worksheet[XLSX.utils.encode_cell(cellAddress)];
    //     //     if (cell && cell.v) {
    //     //       const lines = cell.v.toString().split('\n');
    //     //       const lineHeight = 15; // Adjust the line height as desired
    //     //       const cellHeight = Math.max(lines.length * lineHeight, 25); // Adjust the minimum cell height as desired
    //     //       const key = `${C}-${R}`;
    //     //       if (!cellHeightMap[key] || cellHeight > cellHeightMap[key]) {
    //     //         cellHeightMap[key] = cellHeight;
    //     //       }
    //     //     }
    //     //   }
    //     // }
    //     // worksheet['!rows'] = Object.keys(cellHeightMap).map((key) => ({
    //     //   r: parseInt(key.split('-')[1], 10),
    //     //   hpx: cellHeightMap[key],
    //     //   hidden: false,
    //     // }));
    //     // Adjusting cell heights based on fixed height
    // const cellHeightMap: { [key: string]: number } = {};
    // for (let R = cellRange.s.r; R <= cellRange.e.r; ++R) {
    //   for (let C = cellRange.s.c; C <= cellRange.e.c; ++C) {
    //     const cellAddress = { c: C, r: R };
    //     const cell = worksheet[XLSX.utils.encode_cell(cellAddress)];
    //     if (cell && cell.v) {
    //       const key = `${C}-${R}`;
    //       if (!cellHeightMap[key] || 20 > cellHeightMap[key]) {
    //         cellHeightMap[key] = 30; // Set a fixed height of 30 pixels
    //       }
    //     }
    //   }
    // }

    // // Generate an array of row objects with the fixed height
    // worksheet['!rows'] = Object.keys(cellHeightMap).map((key) => ({
    //   r: parseInt(key.split('-')[1], 10), // Extract the row index from the key
    //   hpx: 30, // Set a fixed height of 30 pixels
    //   hidden: false, // Set the hidden property to false to ensure the row is visible
    // }));

    //     const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    //     const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

    //     this.saveAsExcelFile(excelBuffer, excelFileName);
    //   }









    private saveAsExcelFile(buffer: any, fileName: string): void {
        const data: Blob = new Blob([buffer], {
            type: EXCEL_TYPE
        });
        // const currentDate = new Date();
        // const formattedDate = currentDate.toISOString().slice(0, 10); // Format: YYYY-MM-DD
        const currentDate = new Date();
        const day = String(currentDate.getDate()).padStart(2, '0');
        const month = String(currentDate.getMonth() + 1).padStart(2, '0');
        const year = currentDate.getFullYear();
        
        const formattedDate = `${day}-${month}-${year}`;

        FileSaver.saveAs(data, fileName + '_export_' + formattedDate + EXCEL_EXTENSION);
    }

}